:root {
  //Unordered Lists
  --ul-text-color: #1a1a1a;
  --ul-bullet-color: #F15352;
  --ul-bullet-secondary-color: #d4b677;

  //Ordered Lists
  --ol-text-color: #1a1a1a;
  --ol-number-color: #F15352;
  --ol-bullet-color: #d4b677;

  .dark-mode {
    //Unordered Lists
    --ul-text-color: #1a1a1a;
    --ul-bullet-color: #1a1a1a;
    --ul-bullet-secondary-color: #cccccc;

    //Ordered Lists
    --ol-text-color: #1a1a1a;
    --ol-number-color: #1a1a1a;
    --ol-bullet-color: #cccccc;
  }
}

#main {

  ul,
  ol {
    // @include primary-font-regular;
    @include fontsize(18 28);
    font-weight: 300;


    &.no-bullets {
      list-style: none;
      padding-left: 0;
    }
  }

  ul:not([class]) {
    margin: 0;
    padding-left: 0;
    list-style: none;
    color: (--ul-text-color);

    li {
      // text-indent: -5px;
      padding-left: 0;
      padding-bottom: 0;
      margin-bottom: 7px;

      &:before {
        position: relative;
        display: inline-block;
        font-family: FontAwesome;
        font-size: 12px;
        font-weight: 900;
        top: -1px;
        left: -8px;
        color: var(--ul-bullet-color);
        content: '\f111';
      }

      ul {
        margin-bottom: 0;
        font-size: 16px;
        font-style: italic;

        li {
          &:before {
            font-size: 12px;
            font-style: normal;
            color: var(--ul-bullet-secondary-color);
          }
        }
      }
    }
  }

  ol:not([class]) {
    list-style: none;
    counter-reset: numbers;
    padding-left: 10;
    margin: 0;
    color: (--ol-text-color);

    li {
      // text-indent: -5px;
      counter-increment: numbers;
      margin-bottom: 7px;
      margin-left: 0;
      padding-left: 0;
      padding-bottom: 0;

      &:before {
        position: relative;
        left: -8px;
        display: inline-block;
        font-weight: 600;
        content: counter(numbers);
        color: var(--ol-number-color);
      }

      >ol,
      >ul {
        margin-bottom: 0;
        font-size: 16px;

        li {
          text-indent: -5px;

          &:before {
            content: '\f111';
            font-family: FontAwesome;
            font-weight: 900;
            display: inline-block;
            position: relative;
            font-size: 12px;
            top: -1px;
            left: -8px;
            color: var(--ol-bullet-color);
          }
        }
      }
    }
  }
}
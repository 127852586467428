/* ----------------------------------------------------------------

	blog.scss

-----------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Blog
-----------------------------------------------------------------*/

#posts {
  position: relative;

  .entry-content {
    margin-top: 30px;
  }
}

.entry {
  position: relative;
  margin: 0 0 50px;
  padding: 0 0 50px;
  border-bottom: 2px solid #f5f5f5;

  blockquote {
    p {
      font-weight: 400;
      font-style: italic;
      font-family: $secondary-font;
    }
  }
}

.entry-image {
  margin-bottom: 30px;

  iframe {
    display: block;
  }

  img {
    @include border-radius(3px);
  }
}

.entry-image,
.entry-image>a,
.entry-image .slide a,
.entry-image img {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}

.entry-title {
  h2 {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 24px;

    a {
      color: #333333;

      &:hover {
        color: $theme-color;
      }
    }
  }
}

.entry-meta {
  margin: 10px -10px -15px 0;
  max-height: 30px;
  list-style: none;

  li {
    float: left;
    margin: 0 10px 15px 0;
    color: #999999;
    font-style: italic;
    font-size: 13px;
    font-family: $secondary-font;
    line-height: 14px;

    &:before {
      display: inline-block;
      margin-right: 10px;
      content: '/';
      opacity: 0.5;
    }

    i {
      position: relative;
      top: 1px;
      margin-right: 3px;
      font-size: 14px;
    }

    a {
      color: #999999;

      &:hover {
        color: $theme-color;
      }
    }

    &:first-child {
      &:before {
        display: none;
      }
    }

    ins {
      text-decoration: none;
      font-weight: 600;
    }
  }
}

.single-post {
  .entry-meta {
    margin-bottom: 20px;
  }

  .entry-content {
    .entry-image {
      max-width: 350px;
    }
  }
}

.entry-c {
  position: relative;
  overflow: hidden;
}

.entry-content {
  position: relative;
}

.entry-link {
  display: block;
  padding: 30px 0;
  width: 100%;
  background-color: #f5f5f5;
  color: #444444;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 24px;
  font-family: $heading-font;
  @include border-radius(3px);

  &:hover {
    background-color: $theme-color;
    color: #ffffff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);

    span {
      color: #eeeeee;
    }
  }

  span {
    display: block;
    margin-top: 5px;
    color: #aaaaaa;
    text-transform: none;
    letter-spacing: 0;
    font-weight: normal;
    font-style: italic;
    font-size: 14px;
    font-family: $secondary-font;
  }
}

body {
  &:not(.device-touch) {
    .entry-link {
      @include transition(background-color 0.3s ease-in-out);
    }
  }
}


/* Small Thumbs
-----------------------------------------------------------------*/

.small-thumbs,
.ievent {
  .entry-image {
    float: left;
    margin: 0 30px 0 0;
    width: 300px;

    img {
      @include border-radius(0);
    }
  }
}

.small-thumbs,
.ievent {
  .entry-c {
    .entry-image {
      float: none;
      margin: 0 0 20px 0;
      width: 100%;

      img {
        @include border-radius(0);
      }
    }
  }

  .entry-title {
    h2 {
      font-weight: 600;
      font-size: 20px;
    }
  }
}


/* Small Thumbs - Right
-----------------------------------------------------------------*/

.small-thumbs {
  &.alt {
    .entry-image {
      float: right;
      margin: 0 0 0 30px;
    }

    .entry-c {
      .entry-image {
        float: none;
        margin: 0 0 20px 0;
      }
    }
  }
}


/* Small Thumbs - Both Sidebar
-----------------------------------------------------------------*/

.bothsidebar {
  .small-thumbs {
    .entry-image {
      margin: 0 25px 0 0;
      width: 200px;
    }

    &.alt {
      .entry-image {
        margin: 0 0 0 25px;
      }
    }

    .entry-c {
      .entry-image {
        margin: 0 0 20px 0;
        width: 100%;
      }
    }

    .entry-title {
      h2 {
        font-size: 18px;
      }
    }
  }
}


/* Blog - Search
-----------------------------------------------------------------*/

.blog-search {
  a {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 8px;
    width: 40px;
    background: #F15352;
    text-align: center;
    line-height: 32px;
  }

  path {
    fill: #ffffff;
  }
}


/* Blog - Grid
-----------------------------------------------------------------*/

.post-grid {
  margin-right: -$blog-4-margin;

  .entry {
    float: left;
    margin-right: $blog-4-margin;
    width: blog-grid-item-width(1140px, 4, $blog-4-margin);
  }

  .entry-image {
    img {
      @include border-radius(0);
    }
  }

  .entry-title {
    h2 {
      font-weight: 600;
      font-size: 17px;
    }
  }

  .entry-link {
    font-size: 24px;

    span {
      font-size: 13px;
    }
  }
}


/* Blog - Grid - 3 Columns
-----------------------------------------------------------------*/

.post-grid {
  &.grid-3 {
    margin-right: -$blog-3-margin;

    .entry {
      margin-right: $blog-3-margin;
      width: blog-grid-item-width(1140px, 3, $blog-3-margin);
    }

    .entry-title {
      h2 {
        font-size: 18px;
      }
    }
  }
}


/* Blog - Grid - 3 Columns - Sidebar
-----------------------------------------------------------------*/

.postcontent {
  .post-grid {
    &.grid-3 {
      margin-right: -$blog-3-margin-sb;

      .entry {
        margin-right: $blog-3-margin-sb;
        width: blog-grid-item-width($postcontent, 3, $blog-3-margin-sb);
      }

      .entry-title {
        h2 {
          font-size: 16px;
        }
      }
    }
  }
}


/* Blog - Grid - 2 Columns
-----------------------------------------------------------------*/

.post-grid {
  &.grid-2 {
    margin-right: -$blog-2-margin;

    .entry {
      margin-right: $blog-2-margin;
      width: blog-grid-item-width(1140px, 2, $blog-2-margin);
    }

    .entry-title {
      h2 {
        font-size: 20px;
      }
    }
  }
}


/* Blog - Grid - 2 Columns - Sidebar
-----------------------------------------------------------------*/

.postcontent {
  .post-grid {
    &.grid-2 {
      .entry {
        width: blog-grid-item-width($postcontent, 2, $blog-2-margin-sb);
      }

      .entry-title {
        h2 {
          font-size: 18px;
        }
      }
    }
  }
}


/* Blog - Grid - 2 Columns - Both Sidebar
-----------------------------------------------------------------*/

.bothsidebar {
  .post-grid {
    &.grid-2 {
      .entry {
        width: blog-grid-item-width($bothsidebar, 2, $blog-2-margin-bs);
      }

      .entry-title {
        h2 {
          font-size: 16px;
        }
      }
    }
  }
}


/* Blog - Masonry
-----------------------------------------------------------------*/

.post-masonry {

  .entry-image,
  .entry-image img {
    height: auto !important;
  }
}


/* Blog - Masonry Full
-----------------------------------------------------------------*/

.post-masonry-full {
  margin: -80px 0 -80px -1px !important;

  .entry {
    margin: 0 !important;
    padding: 40px 30px;
    width: 24.9% !important;
    border-color: #e5e5e5;
    border-bottom: 1px dashed;
    border-left: 1px dashed;
  }

  &.grid-3 {
    .entry {
      width: 33.30% !important;
    }
  }
}


/* Blog - Timeline
-----------------------------------------------------------------*/

.timeline-border {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -71px;
  width: 0;
  height: 100%;
  border-left: 1px dashed #cccccc;
}

.post-timeline {
  margin-right: -140px !important;

  .entry {
    margin-right: 140px !important;
    width: 500px !important;
  }

  .entry-timeline {
    position: absolute;
    top: 40px;
    right: -76px;
    left: auto;
    display: none;
    padding-top: 0;
    width: 13px;
    height: 13px;
    border: 2px solid #cccccc;
    background-color: #ffffff;
    color: #aaaaaa;
    text-align: center;
    text-indent: -9999px;
    font-weight: bold;
    font-size: 26px;
    line-height: 1;
    @include border-radius(50%);
    @include transition(all 0.3s ease-in-out);

    div {
      &.timeline-divider {
        position: absolute;
        top: 4px;
        left: -58px;
        width: 50px;
        height: 0;
        border-top: 1px dashed #cccccc;
        @include transition(all 0.3s ease-in-out);
      }
    }

    span {
      display: block;
      margin-top: 3px;
      font-weight: normal;
      font-size: 13px;
    }
  }

  .alt {
    .entry-timeline {
      right: auto;
      left: -77px;

      div {
        &.timeline-divider {
          right: -58px;
          left: auto;
        }
      }
    }
  }
}

.entry {
  &.entry-date-section {
    margin: 50px -70px 80px;
    padding: 0;
    width: 100% !important;
    border: 0;
    text-align: center;

    span {
      display: inline-block;
      padding: 10px 15px;
      border: 2px solid #eeeeee;
      background-color: #ffffff;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: bold;
      font-size: 18px;
      font-family: $heading-font;
    }

    &+.entry {
      .entry-timeline {
        top: 70px;
      }
    }
  }
}

.post-timeline {
  .entry {
    &:hover {

      .entry-timeline,
      .timeline-divider {
        border-color: $theme-color;
        color: $theme-color;
      }
    }
  }
}


/* Blog - Timeline - Sidebar
-----------------------------------------------------------------*/

.postcontent {
  .timeline-border {
    left: 32px;
    margin-left: 0;
  }

  .post-timeline {
    margin-right: 0 !important;
    padding-left: 100px;

    .entry {
      margin-right: 140px !important;
      width: 100% !important;
    }

    .entry-timeline {
      top: 20px;
      right: auto;
      left: -100px;
      display: block;
      padding-top: 10px;
      width: 64px;
      height: 64px;
      border: 3px solid #cccccc;
      background-color: #ffffff;
      text-indent: 0;
      font-size: 24px;

      div {
        &.timeline-divider {
          top: 29px;
          left: 64px;
          width: 32px;
        }
      }
    }
  }
}


/* Individual Post
-----------------------------------------------------------------*/

.ipost {
  .entry-image {
    img {
      @include border-radius(0);
    }
  }

  .entry-title {

    h3,
    h4 {
      margin: 0;
      font-weight: 600;
      font-size: 16px;

      a {
        color: #333333;

        &:hover {
          color: $theme-color;
        }
      }
    }

    h4 {
      font-size: 15px;
    }
  }
}

.ipost {
  .entry-meta {
    margin-right: -10px;

    li {
      margin-right: 10px;
      font-size: 13px;
    }
  }

  .entry-content {
    margin-top: 20px;

    p {
      margin-bottom: 0;
    }
  }
}

.more-link {
  display: inline-block;
  padding: 0 2px;
  border-bottom: 1px solid $theme-color;
  font-style: italic;
  font-family: $secondary-font;

  &:hover {
    border-bottom-color: #555555;
  }
}


/* Related Posts
-----------------------------------------------------------------*/

.overlay-icon {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;

  i {
    color: #ffffff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    font-size: 28px;
    line-height: 48px;
  }
}


/* Small Post
-----------------------------------------------------------------*/

.spost,
.mpost {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px dashed #e5e5e5;
}

.mpost {
  margin-top: 25px;
  padding-top: 25px;
}

.spost,
.mpost {
  &:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
  }
}

.spost,
.mpost {
  .entry-image {
    float: left;
    margin: 0 15px 0 0;
    text-align: center;
  }
}

.spost {

  .entry-image,
  .entry-image a,
  .entry-image img,
  .entry-image i {
    overflow: hidden;
    width: 51px;
    height: 52px;
  }
}

.spost,
.mpost {
  .entry-image {
    a {
      i {
        background-color: #eeeeee;
        color: #666666;
        font-size: 28px;
        line-height: 48px;
      }
    }
  }

  .entry-title {
    h4 {
      margin: 0;
      font-weight: 600;
      font-size: 14px;

      a {
        color: #333333;

        &:hover {
          color: $theme-color;
        }
      }
    }
  }
}

.spost {
  .entry-meta {
    margin: 8px -10px 0 0;
  }
}

.spost,
.mpost {
  .entry-meta {
    li {
      margin: 0 10px 0 0;
      font-size: 13px;
    }
  }
}


/* Medium Post
-----------------------------------------------------------------*/

.mpost {
  .entry-image {
    margin-right: 20px;

    a {
      i {
        font-size: 42px;
        line-height: 128px;
      }
    }
  }

  .entry-title {
    h4 {
      margin: 0;
      font-weight: 600;
      font-size: 17px;
    }
  }

  .entry-meta {
    margin-top: 5px;

    li {
      i {
        margin-right: 2px;
      }
    }
  }

  .entry-content {
    margin-top: 15px;
  }

  .entry-image,
  .entry-image a,
  .entry-image img,
  .entry-image i {
    width: 170px;
    height: 128px;
  }

  .entry-image {

    a,
    img {
      @include border-radius(2px);
    }
  }
}

.post-navigation {
  &+.line {
    margin: 40px 0 50px;
  }
}


/* ----------------------------------------------------------------
	Blog - Author
-----------------------------------------------------------------*/

.author-image {
  float: left;
  margin-right: 15px;
  width: 84px;
  height: 84px;

  img {
    width: 84px;
    height: 84px;
  }
}


/* ----------------------------------------------------------------
	Comments List
-----------------------------------------------------------------*/

#comments {
  position: relative;
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid #eeeeee;
}

.commentlist {
  margin: 0 0 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #eeeeee;
  list-style: none;

  ul {
    list-style: none;

    &:first-child {
      margin-top: 0;
    }

    .comment-wrap {
      margin-left: 25px;
      padding-left: 20px;
    }
  }

  li {
    position: relative;
    margin: 30px 0 0 30px;

    .children {
      margin-top: 0;
    }

    li {
      .children {
        margin-left: 30px;
      }
    }

    .comment-content {
      padding: 0 0 0 15px;
    }

    .comment-meta {
      float: left;
      margin-right: 0;
      line-height: 1;
    }
  }
}

#reviews {
  .commentlist {
    margin: 0 0 20px;
    padding-bottom: 30px;

    li {
      margin-top: 20px;
    }
  }

  .comment-wrap {
    padding: 10px 0 0 35px;
    border: 0;
  }
}

.commentlist {

  li,
  li ul,
  li ul li {
    margin: 30px 0 0 0;
  }
}

.comment-wrap {
  position: relative;
  padding: 20px 20px 20px 35px;
  border: 1px solid #e5e5e5;
  @include border-radius(5px);
}

.commentlist>li,
#reviews .commentlist>li {
  &:first-child {
    margin-top: 0;
    padding-top: 0;
  }
}

.commentlist li .comment-content,
.pingback {
  position: relative;
  overflow: hidden;

  p {
    margin: 20px 0 0 0;
  }
}

.comment-avatar {
  position: absolute;
  top: 15px;
  left: -35px;
  padding: 4px;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  @include border-radius(50%);

  img {
    display: block;
    @include border-radius(50%);
  }
}

.commentlist {
  li {
    .children {
      .comment-avatar {
        left: -25px;
      }
    }
  }
}

.comment-content {
  .comment-author {
    margin-bottom: -10px;
    color: #555555;
    font-weight: bold;
    font-size: 16px;

    a {
      border: none;
      color: #333333;

      &:hover {
        color: $theme-color;
      }
    }

    span {
      display: block;

      &:hover {
        color: #888888;
      }
    }

    span,
    a {
      color: #aaaaaa;
      font-weight: normal;
      font-style: italic;
      font-size: 12px;
      font-family: $secondary-font;
    }
  }
}

.comment-reply-link,
.review-comment-ratings {
  position: absolute;
  top: 4px;
  right: 0;
  left: auto;
  display: block;
  width: 14px;
  height: 14px;
  color: #cccccc;
  text-align: center;
  font-size: 14px;
  line-height: 1;
}

.review-comment-ratings {
  width: auto;
  color: #333333;
}

.comment-reply-link {
  &:hover {
    color: #888888;
  }
}


/* ----------------------------------------------------------------
	Comment Form
-----------------------------------------------------------------*/

#respond,
#respond form {
  margin-bottom: 0;
}

.commentlist {
  li {
    #respond {
      margin: 30px 0 0;
    }

    li {
      #respond {
        margin-left: 30px;
      }
    }
  }
}

#respond {
  p {
    margin: 10px 0 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  label {
    small {
      color: #999999;
      font-weight: normal;
    }
  }

  input[type='text'],
  textarea {
    margin-bottom: 0;
  }

  .col_one_third,
  .col_full {
    margin-bottom: 20px;
  }
}

.fb-comments,
.fb_iframe_widget,
.fb-comments>span,
.fb_iframe_widget>span,
.fb-comments>span>iframe,
.fb_iframe_widget>span>iframe {
  display: block !important;
  margin: 0;
  width: 100% !important;
}


/* Post Elements
-----------------------------------------------------------------*/

img,
div {
  &.alignleft {
    float: left;
    margin: 5px 20px 13px 0;
    max-width: 100%;
  }
}

div {

  &.alignleft,
  &.alignnone,
  &.aligncenter,
  &.alignright {
    >img {
      display: block;
      float: none;
    }
  }
}

img.alignnone,
img.aligncenter,
div.alignnone,
div.aligncenter {
  display: block;
  float: none;
  margin: 10px 0;
}

img.aligncenter,
div.aligncenter,
div.aligncenter img {
  clear: both;
  margin-right: auto;
  margin-left: auto;
}

img,
div {
  &.alignright {
    float: right;
    margin: 5px 0 13px 20px;
  }
}

.wp-caption {
  margin: 10px 20px 13px 20px;
  text-align: center;
  font-style: italic;
  font-family: $body-font;
  font-family: Georgia, 'Times New Roman', Times, serif;

  img,
  img a {
    display: block;
    margin: 0;
  }
}

p {
  &.wp-caption-text {
    display: inline-block;
    margin: 10px 0 0 0;
    padding: 5px 10px;
    background-color: #eeeeee;
    @include border-radius(50px);
  }
}

.wp-smiley {
  margin: 0 !important;
  max-height: 13px;
}
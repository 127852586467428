
/* ----------------------------------------------------------------

	shop.scss

-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Shop
-----------------------------------------------------------------*/

.shop {
	position: relative;
	margin: 0 -$shop-4-margin -$shop-4-margin 0;
}

.product { position: relative; }

.shop .product {
	position: relative;
	float: left;
	padding: 0 $shop-4-margin $shop-4-margin 0;
	width: 25%;
}

.product-image {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
}

.product-image {
	> a,
	.slide a,
	img {
		display: block;
		width: 100%;
		height: 100%;
	}
}

body {
	&:not(.device-touch):not(.device-xs):not(.device-xxs) {
		.product-image {
			& > a {
				@include transition(all .5s ease);
			}
		}
	}
}

.product-image {
	& > a {
		&:nth-of-type(2) {
			opacity: 0;
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
		}
	}
	&:hover {
		& > a {
			&:nth-of-type(2) {
				opacity: 1;
				z-index: 2;
			}
		}
	}
}

.product-desc {
	padding: 15px 0;
	font-size: 14px;
}
.product-title {
	margin-bottom: 7px;
	h3 {
		margin: 0;
		font-size: 20px;
	}
}
.product-title h3 a,
.single-product .product-title h2 a {
	color: #333;
	&:hover {
		color: $theme-color;
	}
}

.product-price {
	font-size: 18px;
	font-weight: 600;
	color: #444;
	margin-bottom: 4px;
	del {
		font-weight: 400;
		font-size: 90%;
		color: #888;
	}
	ins {
		text-decoration: none;
		color: $theme-color;
	}
}

.product-overlay {
	position: absolute;
	width: 100%;
	height: 44px;
	line-height: 44px;
	top: auto;
	bottom: -44px;
	left: 0;
	z-index: 6;
	a {
		display: block;
		float: left;
		width: 50%;
		font-size: 13px;
		color: #333;
		background-color: #F5F5F5;
		text-align: center;
		border-right: 1px solid rgba(0,0,0,0.1);
		&.item-quick-view {
			border-right: 0;
		}
		i {
			position: relative;
			top: 1px;
			font-size: 14px;
			margin-right: 3px;
		}
		&:hover {
			background-color: #333;
			color: #FFF;
			text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
		}
	}
}
body {
	&:not(.device-touch):not(.device-xs):not(.device-xxs) {
		.product-overlay {
			@include transition(bottom .3s ease-in-out);
			a {
				@include transition(all .3s ease-in-out);
			}
		}
	}
}
.product {
	&:hover {
		.product-overlay {
			bottom: 0;
		}
	}
}

.sale-flash {
	position: absolute;
	top: 10px;
	left: 10px;
	padding: 6px 10px;
	background-color: $theme-color;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
	@include border-radius(3px);
	z-index: 5;
	&.out-of-stock {
		background-color: #777;
	}
}

/* Shop - 3 Columns
-----------------------------------------------------------------*/

.shop {
	&.product-3 {
		margin: 0 -$shop-3-margin -$shop-3-margin 0;
	}
}
.product-3 {
	.product {
		padding: 0 $shop-3-margin $shop-3-margin 0;
		width: 33.33333333%;
	}
}

/* Shop - 3 Columns - Sidebar
-----------------------------------------------------------------*/

.postcontent {
	.shop {
		&.product-3 {
			margin: 0 -$shop-3-margin-sb -$shop-3-margin-sb 0;
		}
	}
	.product-3 {
		.product {
			padding: 0 $shop-3-margin-sb $shop-3-margin-sb 0;
		}
	}
}

/* Shop - 2 Columns - Sidebar
-----------------------------------------------------------------*/

.postcontent {
	.shop {
		&.product-2 {
			margin: 0 -$shop-2-margin-sb -$shop-2-margin-sb 0;
		}
	}
	.product-2 {
		.product {
			padding: 0 $shop-2-margin-sb $shop-2-margin-sb 0;
			width: 50%;
		}
	}
}

/* Shop - 2 Columns - Both Sidebar
-----------------------------------------------------------------*/

.bothsidebar {
	.shop {
		&.product-2 {
			margin: 0 -$shop-2-margin-bs -20px 0;
		}
	}
	.product-2 {
		.product {
			padding: 0 $shop-2-margin-bs 20px 0;
		}
	}
}

/* Shop - 1 Column
-----------------------------------------------------------------*/

.shop {
	&.product-1 {
		margin: 0 0 -40px 0;
	}
}
.product-1 {
	.product {
		float: none;
		margin: 0 0 40px 0;
		padding: 0;
		width: 100%;
		&.alt {
			.product-image {
				float: right;
				margin: 0 0 0 30px;
			}
		}
	}
	.product-image {
		float: left;
		width: 300px;
		height: 400px;
		margin-right: 30px;
	}
	.product-desc {
		padding: 0;
		position: relative;
		overflow: hidden;
		.product-price {
			font-size: 20px;
		}
		.product-rating {
			margin-bottom: 15px;
		}
	}
	.product-title {
		h3 {
			font-size: 22px;
		}
	}
}

/* Shop - 1 Column - Both Sidebar
-----------------------------------------------------------------*/

.bothsidebar {
	.product-1 {
		.product {
			border-top: 1px solid #F5F5F5;
			padding-top: 40px;
			&:first-child {
				border-top: 0;
				padding-top: 0;
			}
		}
		.product-image {
			width: 240px;
			height: 320px;
		}
		.product-title {
			h3 {
				font-size: 20px;
			}
		}
		.product-desc {
			.product-price {
				font-size: 16px;
			}
		}
	}
}

/* Shop - Single
-----------------------------------------------------------------*/

.single-product {
	.product {
		width: 100%;
		margin: 0;
	}
	.product-desc {
		padding: 0;
		.line {
			margin: 20px 0;
		}
	}
	.product-title {
		h2 {
			font-size: 28px;
			margin-bottom: 8px;
		}
	}
	.product-price {
		float: left;
		font-size: 24px;
		color: $theme-color;
		margin-bottom: 0;
	}
	.product-rating {
		float: right;
		position: relative;
		top: 9px;
	}
	.product-image,
	.product-image img {
		height: auto;
	}
}

.shop-quick-view-ajax,
.portfolio-ajax-modal {
	position: relative;
	background-color: #FFF;
	width: 800px;
	margin: 0 auto;
}

.quantity {
	float: left;
	margin-right: 30px;
	.qty {
		float: left;
		width: 50px;
		height: 40px;
		line-height: 40px;
		border: 0;
		border-left: 1px solid #DDD;
		border-right: 1px solid #DDD;
		background-color: #EEE;
		text-align: center;
		margin-bottom: 0;
	}
	.plus,
	.minus {
		display: block;
		float: left;
		cursor: pointer;
		border: 0px transparent;
		padding: 0;
		width: 36px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		background-color: #EEE;
		font-size: 16px;
		font-weight: bold;
		@include transition(background-color .2s linear);
		:hover {
			background-color: #DDD;
		}
	}
	.qty,
	.plus,
	.minus {
		:focus{
			box-shadow: none !important;
			outline: 0 !important;
		}
	}
}

.single-product {
	.add-to-cart {
		&.button {
			height: 40px;
			line-height: 40px;
		}
	}
	.feature-box {
		&.fbox-plain {
			&.fbox-small {
				padding-left: 26px;
				.fbox-icon {
					width: 20px;
					i {
						font-size: 18px;
					}
				}
				h3 {
					font-size: 15px;
					font-weight: bold;
					line-height: 18px;
					margin-bottom: 7px;
				}
				p {
					margin-left: -26px;
				}
			}
		}
	}
}
.product-meta {
	font-size: 13px;
	color: #777;
	& > .panel-body {
		margin-bottom: -5px;
		& > span {
			display: block;
			margin-bottom: 5px;
		}
	}
}

/* Individual Product
-----------------------------------------------------------------*/

.product {
	&.iproduct {
		float: none;
		margin: 0;
		width: 100%;
	}
	.product-image,
	.product-image a,
	.product-image img {
		height: auto;
	}
}

/* ----------------------------------------------------------------
	Cart
-----------------------------------------------------------------*/


.cart {
	.remove {
		font-size: 14px;
		color: #FF0000;
		&:hover {
			color: #000;
		}
	}
	th {
		padding: 12px 15px !important;
		font-size: 14px;
		color: #555;
	}
	td {
		padding: 12px 15px !important;
		vertical-align: middle !important;
		border-color: #E5E5E5 !important;
	}
}

.cart-product-thumbnail {
	a {
		display: block;
		width: 68px;
	}
	img {
		display: block;
		width: 64px;
		height: 64px;
		border: 2px solid #EEE;
		&:hover {
			border-color: $theme-color;
		}
	}
}

.cart-product-name a,
.product-name a {
	font-weight: bold;
	font-size: 13px;
	color: #333;
}

.cart-product-name a:hover,
.product-name a:hover {
	color: #666;
}

.cart-product-price,
.cart-product-quantity,
.cart-product-subtotal {
	text-align: center !important;
}

.cart-product-quantity {
	.quantity {
		display: inline-block;
		margin: 0;
		float: none;
	}
}

td {
	&.actions {
		padding: 20px 15px !important;
		vertical-align: top !important;
		background-color: #F5F5F5;
	}
}

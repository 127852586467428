// Grid Settings
$gutter: 30px;

// Breakpoints
$small: 375px;
$medium: 640px;
$large: 1024px;
$xlarge: 1440px;
$xxlarge: 1920px;

// Site Width
$sitewidth: 1920px;
$contentwidth: 1140; //do not add px to this variable
﻿
.search-results-search {
    padding: 10px !important;
    border: 0 !important;
    border-radius: 10px !important;

    .search-submit {
        padding: 10px !important;
        border: 0 !important;
        border-radius: 10px !important;
        color: white !important;
        background: crimson !important;
        margin-left: 20px !important;
    }

    input[type="search"] {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
        border: 0 !important;
        border-radius: 10px !important;
        padding: 10px !important;
    }
}

.search-results {
    tr {
        margin-bottom: 40px;
        display: block;
        span small {
            display: none;
        }
    }
}
:root {
  //Image List
  --icon-list-bg: transparent;
  --icon-list-border-color: #cccccc;

  .dark-mode {
    --icon-list-bg: transparent;
    --icon-list-border-color: #cccccc;
  }
}

// image list
.icon-list {
  display: flex;
  flex-wrap: nowrap;
  margin: 15px 0;

  @include medium-up {
    flex-grow: 0;
  }

  &::before {
    content: '';
  }

  // + .icon-list {
  //   border-top: 1px solid var(--icon-list-border-color);
  //   padding: 35px 0;
  //   margin: 0;
  // }


  h2,
  h3,
  h4 {
    margin-top: 0;
  }

  .icon,
  &__icon {
    display: inline-block;
    margin: -10px 10px 15px 0;

    @include medium-up {
      margin: 0 10px 15px 0;
      overflow: hidden;
    }

    img,
    figure {
      max-height: 50px;
      min-height: 50px;

      @include medium-up {
        min-height: 50px;
        max-height: 100px;
        margin: 0;
      }
    }
  }

  .content,
  &__content {
    width: 100%;
    
    @include medium-up {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 230px);
      align-items: center;
      align-content: center;
    }

    > * {
      width: 100%;
    }

    h1,
    h2,
    h3 {
      @include fontsize(24 30);
      margin: 0 0 5px;
    }

    p {
      @include fontsize(18 28);
      margin-bottom: 10px;
    }
  }
}
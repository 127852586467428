
/*-----------------------------------------------------------------------------------

	Shortcodes: styled-icons.scss

-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Styled Icons
-----------------------------------------------------------------*/


.i-rounded,
.i-plain,
.i-circled,
.i-bordered,
.social-icon {
	display: block;
	float: left;
	margin: 4px 11px 7px 0;
	text-align: center !important;
	font-size: 28px;
	color: #FFF;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
	cursor: pointer;
	font-style: normal;
}

body {
	&:not(.device-touch) {
		.i-rounded,
		.i-plain,
		.i-circled,
		.i-bordered,
		.social-icon { @include transition(all .3s ease); }
	}
}

.i-rounded,
.i-circled,
.i-bordered {
	width: 52px !important;
	height: 52px !important;
	line-height: 52px !important;
	background-color: #444;
	@include border-radius(3px);
}

.i-bordered {
	border: 1px solid #555;
	line-height: 50px !important;
	background-color: transparent !important;
	color: #444;
	text-shadow: none;
}

.i-plain {
	width: 36px !important;
	height: 36px !important;
	font-size: 28px;
	line-height: 36px !important;
	color: #444;
	text-shadow: 1px 1px 1px #FFF;
}

.i-plain {
	&:hover {
		color: #777;
	}
}

.i-circled { @include border-radius(50%); }

.i-light {
	background-color: #F5F5F5;
	color: #444;
	text-shadow: 1px 1px 1px #FFF;
	box-shadow: inset 0 0 15px rgba(0,0,0,0.2);
}

.i-alt {
	background-image: url('images/icons/iconalt.svg');
	background-position: center center;
	background-size: 100% 100%;
}


.i-rounded,
.i-circled {
	&:hover {
		background-color: $theme-color;
		color: #FFF;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
		box-shadow: 0 0 0 rgba(0,0,0,0.2);
	}
}

.i-bordered {
	&:hover {
		background-color: #444 !important;
		color: #FFF;
		border-color: #444;
	}
}


/* Icon Size - Small
-----------------------------------------------------------------*/

.i-small {
	&.i-rounded,
	&.i-plain,
	&.i-circled,
	&.i-bordered {
		margin: 4px 11px 7px 0;
		font-size: 14px;
	}
	&.i-rounded,
	&.i-circled,
	&.i-bordered {
		width: 28px !important;
		height: 28px !important;
		line-height: 28px !important;
	}
	&.i-bordered {
		line-height: 26px !important;
	}
	&.i-plain {
		width: 18px !important;
		height: 18px !important;
		font-size: 14px;
		line-height: 18px !important;
	}
}

/* Icon Size - Small
-----------------------------------------------------------------*/

.i-medium {
	&.i-rounded,
	&.i-plain,
	&.i-circled,
	&.i-bordered {
		margin: 6px 13px 9px 0;
		font-size: 21px;
	}
	&.i-rounded,
	&.i-circled,
	&.i-bordered {
		width: 36px !important;
		height: 36px !important;
		line-height: 36px !important;
	}
	&.i-bordered {
		line-height: 34px !important;
	}

	&.i-plain {
		width: 28px !important;
		height: 28px !important;
		font-size: 20px;
		line-height: 28px !important;
	}
}

/* Icon Size - Large
-----------------------------------------------------------------*/

.i-large {
	&.i-rounded,
	&.i-plain,
	&.i-circled,
	&.i-bordered {
		margin: 4px 11px 7px 0;
		font-size: 42px;
	}
	&.i-rounded,
	&.i-circled,
	&.i-bordered {
		width: 64px !important;
		height: 64px !important;
		line-height: 64px !important;
	}

	&.i-bordered {
		line-height: 62px !important;
	}

	&.i-plain {
		width: 48px !important;
		height: 48px !important;
		font-size: 42px;
		line-height: 48px !important;
	}
}

/* Icon Size - Extra Large
-----------------------------------------------------------------*/

.i-xlarge {
	&.i-rounded,
	&.i-plain,
	&.i-circled,
	&.i-bordered {
		margin: 4px 11px 7px 0;
		font-size: 56px;
	}
	&.i-rounded,
	&.i-circled,
	&.i-bordered {
		width: 84px !important;
		height: 84px !important;
		line-height: 84px !important;
	}

	&.i-bordered {
		line-height: 82px !important;
	}

	&.i-plain {
		width: 64px !important;
		height: 64px !important;
		font-size: 56px;
		line-height: 64px !important;
	}
}

/* ----------------------------------------------------------------
	Icon Overlay
-----------------------------------------------------------------*/

.i-overlay,
.i-overlay a {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	text-align: center;
	background: rgba(0,0,0,0.2) url('/Themes/canvas-template/assets/dist/images/grid.png') repeat;
}

.i-overlay {
	a {
		background: transparent;
	}
	img,
	i {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -24px 0 0 -24px;
		width: 48px !important;
		height: 48px !important;
		font-size: 48px;
		line-height: 1;
		color: #E5E5E5;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.15);
	}
	i {
		margin: -18px 0 0 -18px;
		width: 36px !important;
		height: 36px !important;
		line-height: 36px;
		font-size: 24px;
	}
}

.on-hover {
	&.i-overlay {
		opacity: 0;
		&:hover {
			opacity: 1;
		}
	}
}

body {
	&:not(.device-touch) {
		.on-hover {
			&.i-overlay {
				@include transition(opacity 0.3s linear);
			}
		}
	}
}
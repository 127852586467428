:root {
  //Contained Table
  --table-head-background: #F15352;
  --table-head-border: rgba(white, .25);
  --table-head-text: #ffffff;
  --table-cell-text: #1a1a1a;
  --table-cell-border: #cccccc;

  //Open Table
  --table-open-head-background: #f3f2e9;
  --table-open-head-text: #1a1a1a;
  --table-open-cell-text: #1a1a1a;
  --table-open-cell-background: #f3f2e9;

  .dark-mode {
    --image-list-background: transparent;
    --image-list-border-color: #cccccc;
  }
}

//Reduce specificity for all parts, no nesting for thead, tr, td, th, etc...
table:not([data-arx-type]),
table.tablesaw {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(black, .25);

  @media screen and (min-width: 990px) {
    border: 0;
    border-collapse: inherit;
    border-spacing: 0;
    overflow: hidden;
  }

  thead {
    tr {
      display: none;

      @media screen and (min-width: 990px) {
        display: table-row;
      }

      th {
        background-color: var(--table-head-background);
        color: var(--table-head-text);
        text-transform: none;
        font-size: 18px;
        padding: 20px 40px;
        border-right: 1px solid rgba(white, .25);
        text-align: left;

        &:last-child {
          border-right: none;
        }

        @media screen and (min-width: 990px) {
          border-bottom: 1px solid rgba(white, .25);
        }
      }
    }
  }

  tbody {
    tr {
      &:last-child {
        td {
          @media screen and (min-width: 990px) {
            border-bottom: 0;
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }

      td {
        display: block;
        clear: left;
        padding: 20px;
        border-bottom: 1px solid var(--table-cell-border);
        background: white;

        @media screen and (min-width: 990px) {
          display: table-cell;
          padding: 20px 40px;
          border-right: 1px solid var(--table-cell-border);
        }

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  &.open-table {
    box-shadow: none;

    thead {
      overflow: hidden;

      tr {
        th {
          color: var(--table-open-head-text);
          background-color: var(--table-open-head-background);
          border: 0;

          @media screen and (min-width: 990px) {
            &:first-of-type {
              border-top-left-radius: 50px;
              border-bottom-left-radius: 50px;
            }

            &:last-of-type {
              border-top-right-radius: 50px;
              border-bottom-right-radius: 50px;
            }
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          td {
            background: var(--table-open-cell-background);

            @media screen and (min-width: 990px) {
              &:first-of-type {
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
              }

              &:last-of-type {
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
              }
            }
          }
        }

        td {
          border: 0;
        }
      }
    }
  }
}

table.responsive-table {
  .cell-heading {
    display: block;
    width: 100%;
    padding: 8px 8px 8px 8px;
    font-weight: 700;
    text-transform: uppercase;
    vertical-align: top;
    background: transparent;

    @media screen and (min-width: 640px) {
      display: inline-block;
      width: 40%;
      padding: 0;
    }

    @media screen and (min-width: 990px) {
      display: none;
    }
  }

  .cell-content {
    display: block;
    width: 100%;
    padding: 8px 0 8px 8px;

    @media screen and (min-width: 640px) {
      display: inline-block;
      width: 60%;
      padding: 0;
    }

    @media screen and (min-width: 990px) {
      width: auto;
    }
  }
}

/* ----------------------------------------------------------------

	footer.scss

-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Footer
-----------------------------------------------------------------*/

#footer {
  position: relative;
  border-top: 5px solid rgba(0,0,0,0.2);
  background-color: #eeeeee;
  .footer-widgets-wrap {
    position: relative;
    padding: 80px 0;
  }
}

.device-lg,
.device-md {
  &.sticky-footer {
    #top-bar {
      z-index: 299;
    }
    #header {
      z-index: 199;
    }
    #slider,
    #page-submenu,
    #page-title,
    #content,
    #slider,
    #page-submenu,
    #page-title,
    #content {
      z-index: 2;
    }
    #footer {
      position: fixed;
      top: auto;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
    }
    &:not(.stretched) #footer {
      left: 50%;
      margin-left: -610px;
      width: 1220px;
    }
  }
}

.device-md.sticky-footer:not(.stretched) #footer {
  margin-left: -500px;
  width: 1000px;
}

.footer-widgets-wrap {
  .col_full,
  .col_half,
  .col_one_third,
  .col_two_third,
  .col_three_fourth,
  .col_one_fourth,
  .col_one_fifth,
  .col_two_fifth,
  .col_three_fifth,
  .col_four_fifth,
  .col_one_sixth,
  .col_five_sixth {
    margin-bottom: 0;
  }
}

#copyrights {
  padding: 40px 0;
  background-color: #dddddd;
  font-size: $font-size-base;
  line-height: $line-height-content;
  i {
    &.footer-icon {
      position: relative;
      top: 1px;
      margin-right: 3px;
      width: 14px;
      text-align: center;
      font-size: $font-size-base;
    }
  }
  .col_half {
    margin-bottom: 0 !important;
  }
  a {
    &:first-child {
      margin-left: 0;
    }
  }
  .tright {
    a {
      color: #e7e3d2;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.copyright-links {
  a {
    display: inline-block;
    margin: 0 3px;
    border-bottom: 1px dotted #444444;
    color: #333333;
    &:hover {
      border-bottom: 1px solid #666666;
      color: #555555;
    }
  }
}

.copyrights-menu {
  margin-bottom: 10px;
  a {
    margin: 0 10px;
    border-bottom: 0 !important;
    font-size: $font-size-base;
  }
}

.footer-logo {
  display: block;
  margin-bottom: 30px;
}

#copyrights .social-links {
  @media (max-width: 767px) {
    text-align: center;
  }

  li {
    padding-left: 10px;

    @media (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
      margin-left: 0;
    }

    a {
      color: #e7e3d2;
    }

    a:hover,
    a:focus {
      color: #d4b677;
    }
  }
}

:root {
  //Mobile Tabs
  --tab-accordion-label-color: #000000;
  --tab-accordion-border-color: #737373;
  --tab-accordion-toggle-color: #737373;
  --tab-accordion-border-color-hover: #000000;
  --tab-accordion-toggle-color-hover: #000000;

  //Tabs - Animated
  --tab-animated-bg-color: #cccccc;
  --tab-animated-bg-color-hover: transparent;
  --tab-animated-bg-color-selected: transparent;
  --tab-animated-border-color: #cccccc;
  --tab-animated-text-color: #737373;
  --tab-animated-text-color-hover: #1a1a1a;
  --tab-animated-text-color-selected: #1a1a1a;
  --tab-animated-indicator-color: #1a1a1a;

  //Tabs - Contained
  --tab-contained-bg-color: #d4b677;
  --tab-contained-bg-color-hover: #F15352;
  --tab-contained-bg-color-selected: #F15352;
  --tab-contained-text-color: #1a1a1a;
  --tab-contained-text-color-hover: #f3f2e9;
  ;
  --tab-contained-text-color-selected: #f3f2e9;
  ;

  //Tabs - Open
  --tab-open-bg-color: transparent;
  --tab-open-bg-color-hover: transparent;
  --tab-open-bg-color-selected: transparent;
  --tab-open-text-color: #737373;
  --tab-open-text-color-hover: #F15352;
  --tab-open-text-color-selected: #F15352;

  //Tabs - Open (Underlined)
  --tab-open-underlined-bg-color: transparent;
  --tab-open-underlined-bg-color-hover: transparent;
  --tab-open-underlined-bg-color-selected: transparent;
  --tab-open-underlined-text-color: #737373;
  --tab-open-underlined-text-color-hover: #F15352;
  --tab-open-underlined-text-color-selected: #F15352;
  --tab-open-underlined-border-color: #cccccc;
  --tab-open-underlined-border-color-hover: #F15352;
  --tab-open-underlined-border-color-selected: #F15352;

  //Tabs - Icon
  --tab-icon-bg-color: #d4b677;
  --tab-icon-bg-color-hover: #F15352;
  --tab-icon-bg-color-selected: #F15352;
  --tab-icon-text-color: #1a1a1a;
  --tab-icon-text-color-hover: #ffffff;
  --tab-icon-text-color-selected: #ffffff;
}

//Reduce specifity to 1 for all selectors
.tabs {
  position: relative;
  margin-bottom: 20px;

  //  &__scroll-left,
  //  &__scroll-right
  &.scroll-left:before,
  &.scroll-right:after {
    position: absolute;
    z-index: 99;
    width: 40px;
    height: 60px;
    content: '';
  }

  &.scroll-left:before {
    top: 0;
    left: 0;
    background: linear-gradient(to right, rgba(white, 1) 0%, rgba(white, 0) 100%);
  }

  &.scroll-right:after {
    top: 0;
    right: 0;
    background: linear-gradient(to left, rgba(white, 1) 0%, rgba(white, 0) 100%);
  }

  //&__label
  .tab-label {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 0 10px;
    margin: 0;
    font-weight: 600;
    color: var(--tab-accordion-label-color);
    cursor: pointer;
    @include fontsize(18 36);

    @include large-up {
      display: none;
    }

    &:before {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: var(--tab-accordion-border-color);
      border-radius: 2px;
      content: '';

      @include large-up {
        display: none;
      }
    }

    &:after {
      content: '';
      width: 16px;
      height: 10px;
      background-image: url(/themes/Atlas/assets/dist/images/ICN-arrow.svg);
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 1;
      position: absolute;
      top: 14px;
      right: 20px;
      background-color: transparent;

      @include large-up {
        display: none;
      }

      @at-root .tabs.accordion.tabs-open .tab-label:after {
        transform: rotate(180deg);
      }
    }

    &:hover,
    &:focus {
      &:before {
        background: darkgray;
      }

      &:after {
        color: darkgray;
      }
    }
  }

  //&__indicator
  .tab-indicator {
    position: relative;
    top: 58px;
    left: 0;
    z-index: 101;
    display: inline-block;
    width: 100%;
    height: 8px;
    background: black;
    border-radius: 30px;
    content: '';
  }
}

//.tab-container
.tab-con {
  position: relative;
  padding: 0 0 4px;
  margin: 0;
  overflow-x: auto;
  overflow-y: visible;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  // Not sure about the use of the @at-root -
  // could be helpful with BEM since we are wanting low specificity this will help with
  // organization, will require some discussion on best practices
  @at-root .tabs.accordion .tab-con {
    padding: 0;
    margin: 0;
    overflow: visible;
    white-space: normal;

    @include large-up {
      overflow-x: auto;
      overflow-y: visible;
      white-space: nowrap;
    }
  }
}

.tab-list {
  display: none;
  padding: 0;
  margin: 0;
  font-weight: 600;
  list-style: none;
  background: white;
  @include fontsize(18 20);

  &[role='tablist'] {
    display: inline-block;

    @at-root .tabs.accordion.icon .tab-list[role='tablist'] {
      @include large-up {
        width: 100%;

        li,
        a {
          width: 100%;
        }
      }
    }

    @at-root .tabs.accordion .tab-list[role='tablist'] {
      position: absolute;
      top: 100%;
      z-index: 100;
      display: none;
      width: 100%;

      @include large-up {
        position: relative;
        top: auto;
        z-index: auto;
        display: inline-flex;
        width: auto;
        background: transparent;
        align-items: flex-end;
      }
    }

    @at-root .tabs.accordion.tabs-open .tab-list[role='tablist'] {
      display: block;
    }

    @at-root .tabs.animated .tab-list[role='tablist'] {
      @include large-up {
        display: flex;
        background: transparent;
        flex-flow: row wrap;
        justify-content: center;
      }
    }
  }

  // Use figure as root for img selectors, best to use class if possible
  img {
    display: none;

    @include large-up {
      display: block;
      margin: 0 auto 5px !important;
    }
  }

  //&__item
  li {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    white-space: nowrap;

    @include large-up {
      margin: 0 5px 0 0;
    }

    &:last-child {
      margin: 0;
    }

    @at-root .tabs.accordion .tab-list li {
      display: block;

      @include large-up {
        display: inline-block;
      }
    }

    @at-root .tabs.animated .tab-list li {
      margin: 0;

      @include large-up {
        flex: 1;
      }

      &:last-child {
        margin: 0;
      }


      //   &__link
      a {
        position: relative;
        display: block;
        padding: 12px 19px;
        color: black;
        text-align: center;
        text-decoration: none;
        background: snow;

        @at-root .tabs.accordion .tab-list li a {
          text-align: left;

          @include large-up {
            text-align: center;
          }
        }

        @at-root .tabs.animated .tab-list li a {
          color: #737373;

          @include large-up {
            text-align: center;
            background: transparent;
            border-bottom: 4px solid #ccc;
          }
        }

        &:hover,
        &:focus {
          color: black;
          background: #737373;
          outline: 0;

          @at-root {

            .tabs.animated .tab-list li a:hover,
            .tabs.animated .tab-list li a:focus {
              @include large-up {
                background: transparent;
              }
            }
          }
        }

        &[aria-selected] {
          padding: 12px 19px;
          color: black;
          background: #ccc;
          border-color: transparent;

          &:hover,
          &:focus {
            color: black;
            background: #737373;
          }

          @at-root {
            .tabs.animated .tab-list li a[aria-selected] {
              @include large-up {
                background: transparent;
              }
            }

            .tabs.animated .tab-list li a[aria-selected]:hover,
            .tabs.animated .tab-list li a[aria-selected]:focus {
              @include large-up {
                background: transparent;
                border-color: #ccc;
              }
            }
          }
        }
      }
    }

    @at-root .tabs.tab-open--underline .tab-list li {
      @include large-up {
        margin-right: 30px;
      }
    }

    @at-root .tabs.tab-open .tab-list li {
      @include large-up {
        margin-right: 30px;
      }
    }

    @at-root .tabs.icon .tab-list li {
      @include large-up {
        margin-right: 40px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    // &__link
    a {
      position: relative;
      display: block;
      padding: 12px 19px;
      color: var(--tab-contained-text-color);
      text-align: center;
      text-decoration: none;
      background: var(--tab-contained-bg-color);

      @include large-up {
        display: inline-block;
        padding: 15px 36px;
        color: var(--tab-contained-text-color);
        background: var(--tab-contained-bg-color);
        border-bottom: 0;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        transition: all 0.25s ease-in-out;
        @include fontsize(18 24);
      }

      @at-root .tabs.icon .tab-list a {
        @include large-up {
          padding: 25px 30px 30px;
          background: var(--tab-icon-bg-color);
          color: var(--tab-icon-text-color);
          border-radius: 10px;
          @include fontsize(22 30);

          &:hover,
          &:focus {
            color: var(--tab-icon-text-color-hover);
            background: var(--tab-icon-bg-color-hover);

            img {
              filter: brightness(0) invert(1); //Converts to white
            }
          }
        }
      }

      @at-root .tabs.tab-open .tab-list a {
        @include large-up {
          padding: 10px 0;
          color: var(--tab-open-text-color);
          text-transform: uppercase;
          background: var(--tab-open-bg-color);
          border-radius: 0;
          @include fontsize(18 30 50);

          &:hover,
          &:focus {
            color: var(--tab-open-text-color-hover);
          }
        }
      }

      @at-root .tabs.tab-open--underline .tab-list a {
        @include large-up {
          padding: 10px 0;
          color: var(--tab-open-underlined-text-color);
          background: var(--tab-open-underlined-bg-color);
          border-radius: 0;
          @include fontsize(22 30);

          &::after {
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            height: 3px;
            background: var(--tab-open-underlined-border-color);
            border-radius: 50px;
            content: '';
            transition: all 0.25s ease-in-out;
            opacity: .3;
          }

          &:hover,
          &:focus {
            color: var(--tab-open-underlined-text-color-hover);

            &::after {
              background: var(--tab-open-underlined-border-color-hover);
            }
          }
        }
      }

      &::before {
        content: none;
      }

      &:hover,
      &:focus {
        color: var(--tab-contained-text-color-hover);
        background: var(--tab-contained-bg-color-hover);
      }

      &[aria-selected] {
        padding-bottom: 20px;
        color: var(--tab-contained-text-color-selected);
        background: var(--tab-contained-bg-color-selected);

        @at-root .tabs.tab-open .tab-list a[aria-selected] {
          padding-bottom: 10px;
          color: var(--tab-open-text-color-selected);
          background: var(--tab-open-bg-color-selected);
        }

        @at-root .tabs.tab-open--underline .tab-list a[aria-selected] {
          padding-bottom: 10px;
          color: var(--tab-open-underlined-text-color-selected);
          background: var(--tab-open-underlined-bg-color-selected);

          &::after {
            background: var(--tab-open-underlined-border-color-selected);
          }
        }

        @at-root .tabs.icon .tab-list a[aria-selected] {
          padding: 25px 30px 30px;
          color: var(--tab-icon-text-color-selected);
          background: var(--tab-icon-bg-color-selected);

          img {
            filter: brightness(0) invert(1); //Converts to white
          }
        }

        &:hover,
        &:focus {
          color: white;
          background: #1a1a1a;
          border-color: #1a1a1a;
        }
      }
    }
  }
}

.tab-content,
.tab-content--icon {
  padding: 20px;

  @include large-up {
    padding: 40px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 0 25px rgba(black, 0.25);
  }

  @at-root .tabs.animated .tab-content {
    padding: 10px 0 0;

    @include large-up {
      padding-top: 50px;
      box-shadow: none;
    }
  }

  @at-root .tabs.tab-open .tab-content {
    padding: 10px 0 0;

    @include large-up {
      padding: 0;
      box-shadow: none;
    }
  }

  @at-root .tabs.tab-open--underline .tab-content {
    padding: 10px 0 0;

    @include large-up {
      box-shadow: none;
    }
  }

  @at-root .tab-content--icon {
    padding: 10px 0 0;

    @include large-up {
      box-shadow: none;
    }
  }

  &[aria-hidden='true'] {
    display: none;
  }

  .tab-icon:not([data-arx-type='image']) {
    display: none;
  }

  .tab-icon {
    &[data-arx-type="image"] {
      position: relative;
      padding-top: 10px;
      margin-bottom: 15px;
      background: repeating-linear-gradient(to right, rgba(0, 88, 251, 0.04), rgba(0, 88, 251, 0.04) calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc(100% / var(--arx-grid-columns)));
      outline: 2px dashed rgba(0, 88, 251, 0.2);

      @include large-up {
        padding: 40px;
        box-shadow: 0 0 25px rgba(black, 0.25);
      }

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        padding: 2px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        background: rgba(0, 88, 251, 0.04);
        border: 1px dashed rgba(0, 88, 251, 0.2);
        border-top: 0;
        border-right: 0;
        content: 'Tab Icon';
      }
    }
  }

  .tab-title:not([data-arx-type='heading']) {
    display: none;
  }

  &[data-arx-type='layer'],
  &[data-arx-type='tabs'],
  &[data-arx-type='icontabs'] {
    position: relative;
    padding-top: 10px;
    margin-bottom: 15px;
    background: repeating-linear-gradient(to right, rgba(0, 88, 251, 0.04), rgba(0, 88, 251, 0.04) calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc(100% / var(--arx-grid-columns)));
    outline: 2px dashed rgba(0, 88, 251, 0.2);

    @include large-up {
      padding: 40px;
      box-shadow: 0 0 25px rgba(black, 0.25);
    }

    .tab-title {
      display: block !important;
    }

    h2 {
      display: block;
    }

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      padding: 2px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      background: rgba(0, 88, 251, 0.04);
      border: 1px dashed rgba(0, 88, 251, 0.2);
      border-top: 0;
      border-right: 0;
      content: 'Tab';
    }
  }
}

.tab-content.classic[data-arx-type='tabs'],
.tab-content.classic[data-arx-type='layer'] {
  &::before {
    content: 'Tab - Classic';
  }
}

.tab-content.tab-open[data-arx-type='tabs'],
.tab-content.tab-open[data-arx-type='layer'] {
  &::before {
    content: 'Tab - Open (No Underline)';
  }
}

.tab-content.tab-open--underline[data-arx-type='tabs'],
.tab-content.tab-open--underline[data-arx-type='layer'] {
  &::before {
    content: 'Tab - Open Underline';
  }
}

.tab-content.animated[data-arx-type='tabs'],
.tab-content.animated[data-arx-type='layer'] {
  &::before {
    content: 'Tab - Animated';
  }
}

.tab-content--icon[data-arx-type='icontabs'],
.tab-content--icon[data-arx-type='layer'] {
  &::before {
    content: 'Tab - Icon';
  }
}
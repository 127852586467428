a.skipcontent {
  padding: 6px 8px;
  position: absolute;
  top: -40px;
  left: 20px;
  color: white;
  z-index: 1001;
  border-right: 1px solid white;
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: transparent;
  -webkit-transition: top 1s ease-out, background 1s linear;
  transition: top 1s ease-out, background 1s linear;

  &:focus {
    position: absolute;
    left: 20px;
    top: 0px;
    background: #930B07;
    outline: 0;
    -webkit-transition: top .1s ease-in, background .5s linear;
    transition: top .1s ease-in, background .5s linear;
  }
}

a:focus {
  outline: 1px dotted #F15352 !important;
}
:root {
  //Content Calls to Action (cta-billboard)
  --billboard-bg-color: #1a1a1a;
  --billboard-bg-color-secondary: #f3f2e9;
  --billboard-bg-color-third: #F15352;
  --billboard-text-color: white;
  --billboard-text-color-secondary: #1a1a1a;
  --billboard-text-color-third: white;
  --billboard-button-bg-color: transparent;
  --billboard-button-bg-color-secondary: #1a1a1a;
  --billboard-button-text-color: white;
  --billboard-button-text-color-secondary: white;
  --billboard-button-text-color-third: white;
  --billboard-button-border-color: white;
  --billboard-button-border-color-hover: white;
  --billboard-button-border-color-secondary: white;
  --billboard-button-border-color-secondary-hover: white;
  --billboard-button-bg-color-hover: white;
  --billboard-button-bg-color-secondary-hover: #414141;
  --billboard-button-text-color-hover: #1a1a1a;
  --billboard-button-text-color-secondary-hover: white;

  .dark-mode {
    //Content Calls to Action (cta-billboard)
    --billboard-bg-color: #1a1a1a;
    --billboard-bg-color-secondary: #efefef;
    --billboard-text-color: white;
    --billboard-text-color-secondary: #1a1a1a;
    --billboard-button-bg-color: transparent;
    --billboard-button-bg-color-secondary: #1a1a1a;
    --billboard-button-text-color: white;
    --billboard-button-text-color-secondary: white;
    --billboard-button-border-color: white;
    --billboard-button-border-color-hover: white;
    --billboard-button-border-color-secondary: white;
    --billboard-button-border-color-secondary-hover: white;
    --billboard-button-bg-color-hover: white;
    --billboard-button-bg-color-secondary-hover: #414141;
    --billboard-button-text-color-hover: #1a1a1a;
    --billboard-button-text-color-secondary-hover: white;
  }
}

.cta-billboard {
  position: relative;
  background: var(--billboard-bg-color);
  width: 100%;
  max-width: 100vw;
  margin: 0 0 30px;
  overflow: hidden;
  border-radius: 10px;

  figure {
    margin: 0 auto;

    img {
      min-width: 100px;
      min-height: 100px;
    }
  }

  &.secondary-color {
    background: var(--billboard-bg-color-secondary);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: var(--billboard-text-color-secondary);
    }

    .button--primary {
      background: var(--billboard-button-bg-color-secondary);
      color: var(--billboard-button-text-color-secondary);
      border: 1px solid var(--billboard-button-border-color-secondary);

      &:hover,
      &:focus {
        background: var(--billboard-button-bg-color-secondary-hover);
        color: var(--billboard-button-text-color-secondary-hover);
        border-color: var(--billboard-button-border-color-secondary-hover);
      }
    }
  }

  &.third-color {
    background: var(--billboard-bg-color-third);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: var(--billboard-text-color-third);
    }
  }


  &.fullwidth {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-bottom: 0;
    border-radius: 0;
  }

  &[data-arx-type] {
    margin-bottom: 30px !important;
  }

  &--image:not([data-arx-type]),
  &__image:not([data-arx-type]) {
    position: absolute;
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    overflow: hidden;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(black, 0.5);
      content: '';
    }

    img {
      position: absolute;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
      left: 0;
    }
  }

  &--image[data-arx-type] {
    position: relative;
    outline: rgba(0, 88, 251, .2) dashed;
    margin: 10px 0 20px !important;
    max-width: 100% !important;
    padding: 20px;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 4;
      display: inline-block;
      padding: 2PX;
      font-size: 12PX;
      font-weight: 700;
      text-transform: uppercase;
      background: rgba(0, 88, 251, .04);
      border: 0.0625rem dashed rgba(0, 88, 251, .2);
      border-top: 0;
      border-right: 0;
      content: "Billboard Background Image";
    }
  }

  &__content,
  .content {
    position: relative;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    // align-items: center;
    // bottom: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    text-align: center;
    z-index: 1;
    padding: 50px 25px;

    @include medium-up {
      padding: 110px 50px;
      // max-width: 1160px;
      @include width-restrict(35, 60, $contentwidth);
      margin: 0 auto;
    }
  }

  .button--primary {
    background: var(--billboard-button-bg-color);
    color: var(--billboard-button-text-color);
    border: 1px solid var(--billboard-button-border-color);

    &:hover,
    &:focus {
      background: var(--billboard-button-bg-color-hover);
      color: var(--billboard-button-text-color-hover);
      border-color: var(--billboard-button-border-color-hover);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 20px;
    color: var(--billboard-text-color);
    font-size: 22px;
    line-height: 30px;

  }

  ul,
  ol {
    width: 100%;
  }

  p {
    width: 100%;
    @include fontsize(20 30);
    color: var(--billboard-text-color);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &[data-arx-type] {
    .img {
      margin: 0 auto;
      left: auto;
      width: 100%;
      max-width: 100%;

      &:after {
        display: none;
      }
    }

    .content {
      position: relative;
    }
  }
}
:root {
  //Labels and Inputs
  --form-label-color: #1a1a1a;
  --form-heading-color: #1a1a1a;
  --form-input-border-color: #b3b3b3;
  --form-input-border-color-hover: #1a1a1a;
  --form-input-text-color: #1a1a1a;
  --form-input-bg-color: #ffffff;

  //Checkboxes
  --form-cb-checkmark-color: #ffffff;
  --form-cb-bg-color: #ffffff;
  --form-cb-bg-checked-color: #F15352;

  //Radio Buttons
  --form-rb-dot-color: #F15352;
  --form-rb-background-color: white;

  //Submit Button
  --form-submit-button-text-color: #ffffff;
  --form-submit-button-bg-color: #F15352;
  --form-submit-button-text-color-hover: #ffffff;
  --form-submit-button-bg-color-hover: #333333;

  .dark-mode {
    --form-label-color: #f2f2f2;
    --form-heading-color: #f2f2f2;
    --form-input-border-color: #f2f2f2;
    --form-input-text-color: #f2f2f2;
    --form-input-bg-color: #b3b3b3;
  }
}

//Will exist inside of antilles-grid layout
// .form-field{
//   __input-text
//     --error
//   __input-date
//     --error
//   __input-file
//     --error
//   __input-textarea
//     --error
//   __input-checkbox
//     --error
//   __input-checkbox-list
//     --error
//   __input-radio-list
//     --error
//   __input-select
//     --error

//   __text-snippet
//   __text-header
// }


.form-box-content .content-form,
.content-form {
  margin: 0;

  h2 {
    padding-left: 0;
    width: 100%;
  }

  .form-header {
    p {
      padding: 0;
    }
  }

  fieldset {
    width: 100%;

    legend {
      padding: 0;
    }
  }

  input[type='text'],
  input[type='file'],
  input[type='date'],
  input[type='datetime'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  input[type='time'],
  input[type='url'],
  input[type='ssn'],
  textarea {
    // @include primary-font-book;
    @include fontsize(16 24);
    display: block;
    width: 100%;
    padding: 12px 20px;
    margin-top: 5px;
    margin-bottom: 35px;
    background: var(--form-input-bg-color);
    border: 1px solid var(--form-input-border-color);
    border-radius: 10px;

    &:focus {
      border-color: var(--form-input-border-color-hover);
    }
  }

  input[type='file'] {
    padding: 15px 25px;
    margin-top: 8px;
  }

  input[type='file']::-webkit-file-upload-button {
    cursor: pointer;
    border: 1px solid var(--form-input-border-color);
    appearance: none;
  }

  textarea {
    resize: none;
  }

  label {
    // @include primary-font-bold;
    @include fontsize(18 24);
  }

  select {
    // @include primary-font-book;
    @include fontsize(16 18);
    width: 100%;
    padding: 15px 20px;
    margin-top: 5px;
    margin-bottom: 35px;
    background: var(--form-input-bg-color);
    background-image: url('/themes/Atlas/assets/dist/images/icn-arrow.svg');
    background-position: 97% 50%;
    background-repeat: no-repeat;
    border: 1px solid var(--form-input-border-color);
    border-radius: 10px;
    cursor: pointer;

    &:focus {
      border: 1px solid var(--form-input-border-color-hover);
    }
  }

  .checkbox-list {
    padding-left: 0;
    padding-bottom: 20px;

    label {
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      margin-left: 0;
    }

    input[type='checkbox'] {
      position: absolute;
      cursor: pointer;
      opacity: 0;

      +label {
        position: relative;
        display: inline-block;
        padding-left: 32px;
        font-weight: normal;
        margin-bottom: 0;

        &:before,
        &:after {
          position: absolute;
          display: inline-block;
          content: '';
        }

        &:before {
          top: -2px;
          left: 0;
          width: 25px;
          height: 25px;
          background-color: var(--form-cb-bg-color);
          border-radius: 5px;
          border: 1px solid var(--form-input-border-color);
        }

        &:after {
          top: 0;
          left: 0;
          content: none;
        }
      }

      &:checked+label {
        &:after {
          top: -1px;
          left: 5px;
          font-family: FontAwesome;
          font-size: 16px;
          font-weight: 900;
          color: var(--form-cb-checkmark-color);
          content: '\f00c';
        }

        &:before {
          background-color: var(--form-cb-bg-checked-color);
          content: '';
          box-shadow: none;
          border: 1px solid var(--form-input-border-color-hover);
        }
      }

      &:focus+label {
        &:before {
          outline: 5px auto -webkit-focus-ring-color;
        }
      }
    }
  }

  .hide {
    display: none;
  }

  .radio-list {
    padding-left: 0;
    padding-bottom: 20px;

    label {
      margin-bottom: 10px;
    }

    input[type='radio'] {
      position: absolute;
      cursor: pointer;
      opacity: 0;

      +label {
        position: relative;
        display: inline-block;
        padding-left: 36px;
        margin-top: 5px;
        margin-bottom: 0;
        font-weight: normal;

        &:before {
          content: '';
          position: absolute;
          top: -4px;
          left: 0;
          width: 25px;
          height: 25px;
          background-color: var(--form-rb-background-color);
          border-radius: 50px;
          border: 1px solid var(--form-input-border-color);
        }
      }

      &:checked+label {
        &:after {
          position: absolute;
          top: 1px;
          left: 5px;
          width: 15px;
          height: 15px;
          background-color: var(--form-rb-dot-color);
          border-radius: 50px;
          content: '';
        }
      }

      &:focus+label {
        &:before {
          outline: 5px auto -webkit-focus-ring-color;
        }
      }
    }

    ul {
      padding: 0 0 0 5px;
      margin-left: 0 !important;

      li {
        margin-left: 0;

        &:before {
          display: none !important;
        }
      }
    }
  }

  .alt-label {
    position: relative;
    margin-top: 24px;

    label {
      position: absolute;
      top: 19px;
      left: 20px;
      transition: all .33s ease-in-out;
      z-index: 2;
    }

    &.focused {
      label {
        top: -24px;
        left: 0;
      }
    }

  }

  .col-last {
    margin-right: 0;
    clear: right;
  }

  .error-msg {
    position: absolute;
    left: 0;
    bottom: 0;
    float: left;
    top: auto;
    margin-bottom: 10px;
  }

  .required {
    label {
      &:after {
        position: relative;
        font-family: FontAwesome;
        font-weight: 600;
        color: red;
        content: '\f069';
      }
    }
  }

  .upload {
    display: inline-flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
}

.form-builder-submit-controls {
  input[type='submit'] {
    // @include primary-font-bold;
    @include fontsize(16 24);
    font-weight: 600;
    text-transform: uppercase;
    color: var(--form-submit-button-text-color);
    background: var(--form-submit-button-bg-color);
    border: 0;
    border-radius: 10px;
    padding: 12px 20px;
    min-width: 160px;
    cursor: pointer;
    transition: all .25s ease-in-out;

    &:hover,
    &:focus {
      color: var(--form-submit-button-text-color-hover);
      background: var(--form-submit-button-bg-color-hover);
    }
  }
}

.form-footer {
  p {
    br[data-mce-bogus] {
      display: none;
    }
  }
}

.form-header,
.form-footer {
  p:first-of-type>br:first-of-type {
    display: none;
  }
}
@charset 'utf-8';

/*-----------------------------------------------------------------------------------

    Theme Name: Canvas
    Theme URI: http://themes.semicolonweb.com/html/canvas
    Description: The Multi-Purpose Template
    Author: SemiColonWeb
    Author URI: http://themeforest.net/user/semicolonweb
    Version: 4.1.1

    LESS Stylesheet

-----------------------------------------------------------------------------------*/

// Initialize
// @import 'components/variables.scss';
// @import 'components/mixins.scss';

// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';
@import 'presets/grid-settings';
@import 'presets/helper-classes';

// Additional Presets & Variables
@import 'presets/antilles-grid';
@import 'components/elements/font-icons.scss';
@import 'vendor/bootstrap/bootstrap-sprockets.scss';
@import 'vendor/bootstrap/bootstrap.scss';
@import 'vendor/article-columns';
@import 'vendor/article-grid';
@import 'vendor/forms';

// Core CSS
@import 'components/general.scss';
@import 'components/typography.scss';
@import 'components/helpers.scss';
@import 'components/layouts.scss';
// Content Blocks
@import 'components/images';
@import 'components/icon-lists';
@import 'components/cta/highlighted-text-block';
@import 'components/cta/highlighted-text-box';
@import 'components/cta/cta';
@import 'components/cta/cta-banner';
@import 'components/cta/cta-billboard';
@import 'components/cta/cta-image';
@import 'components/cta/featured-boxed-item';
@import 'components/cta/image-content-banner';
@import 'components/modal';
@import 'components/accordions';
@import 'components/lists';
@import 'components/forms';
@import 'components/buttons';
@import 'components/containers';
@import 'components/blockquote';
@import 'components/tables.scss';
@import 'components/tabs.scss';
@import 'components/search.scss';
@import 'components/topbar.scss';
@import 'components/header.scss';
@import 'components/sliders.scss';
@import 'components/swiper.scss';
@import 'components/pagetitle.scss';
@import 'components/content.scss';
@import 'components/portfolio.scss';
@import 'components/blog.scss';
@import 'components/shop.scss';
@import 'components/dark.scss';
@import 'components/events.scss';
@import 'components/twitter.scss';
@import 'components/animate.scss';
@import 'components/sidenav.scss';
@import 'components/sticky-social.scss';
// Shortcodes
@import 'components/shortcodes.scss';
// Footer
@import 'components/footer.scss';
// Widgets
@import 'components/widgets.scss';
@import 'components/elements/carousel.scss';
@import 'components/elements/typeahead.scss';
// Extras
@import 'components/extras.scss';
@import 'components/responsive-rtl.scss';
@import 'components/responsive.scss';
@import 'components/skip-content.scss';
@import 'components/uhlmann.scss';
:root {
  //Featured Box Item
  --fbi-bg-color: #f3f2e9;;
  --fbi-text-color: #1a1a1a;
  --fbi-dropshadow-bg-color: white;
  --fbi-darkbackground-bg-color: #1a1a1a;
  --fbi-darkbackground-text-color: #ffffff;

  .dark-mode {
    //Featured Box Item
    --fbi-bg-color: #efefef;
    --fbi-text-color: #1a1a1a;
    --fbi-dropshadow-bg-color: white;
  }
}

.featured-boxed-item {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  background: var(--fbi-bg-color);
  width: 100%;
  margin-bottom: 20px;
  z-index: 1;
  overflow: hidden;
  border-radius: 10px;

  @media screen and (min-width: 1024px) {
    flex-wrap: nowrap;
    margin: 0 auto 40px;
  }

  &.full-width {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    min-height: 500px;
    border-radius: 0;
    margin-bottom: 0;
    max-width: $sitewidth;

    .featured-boxed-item__content {
      @media screen and (min-width: 1024px) {
        width: 50%;
        padding-left: 10%;
        padding-right: 15%;
      }
    }

    @media screen and (min-width: $sitewidth) {
      margin-left: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.dark-background {
    background-color: var(--fbi-darkbackground-bg-color);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .featured-boxed-item__content {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      ul,
      ol,
      li {
        color: var(--fbi-darkbackground-text-color);
      }
    }
  }

  &.drop-shadow {
    background: var(--fbi-dropshadow-bg-color);
    box-shadow: 0 0 25px rgba(black, .25);
  }

  &.image-right {
    @media screen and (min-width: 1024px) {
      flex-direction: row-reverse;
    }

    .featured-boxed-item__image {
      @media screen and (min-width: 1024px) {
        left: auto;
      }
    }
  }

  &.equal-width {
    .featured-boxed-item__content {
      @media screen and (min-width: 1024px) {
        width: 50%;
      }
    }

    .featured-boxed-item__image {
      @media screen and (min-width: 1024px) {
        width: 50%;
        max-width: 50%;
      }
    }
  }

  &__content {
    display: block;
    width: 100%;
    padding: 30px 20px;
    margin: 0;

    @media screen and (min-width: 1024px) {
      width: 53%;
      padding: 60px 60px 75px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      // @include primary-font-bold;
      color: var(--fbi-text-color);
      margin-bottom: 0;
    }

    p {
      // @include primary-font-regular;
      @include fontsize(18 28);
      color: var(--fbi-text-color);
      margin-top: 0;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    position: relative;
    height: 300px;
    width: 100%;

    @media screen and (min-width: 1024px) {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      max-width: 47%;
      min-height: auto;
      height: 100%;
    }

    figure {
      @media screen and (min-width: 1024px) {
        max-width: 50%;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
:root {
  //Primary Button
  --button-bg-color: #F15352;
  --button-bg-color-hover: #333333;
  --button-text-color: #ffffff;
  --button-text-color-hover: #ffffff;

  //Secondary Button
  --button-secondary-bg-color: #f3f2e9;
  --button-secondary-bg-color-hover: #d4b677;
  --button-secondary-text-color: #1a1a1a;
  --button-secondary-text-color-hover: #1a1a1a;

  //Outline Button Default
  --button-outline-bg-color: transparent;
  --button-outline-bg-color-hover: transparent;
  --button-outline-text-color: #1a1a1a;
  --button-outline-text-color-hover: #1a1a1a;
  --button-outline-border-color: #F15352;
  --button-outline-border-color-hover: #333333;

  //White Outline Button for dark backgrounds
  --button-outline-white-bg-color: transparent;
  --button-outline-white-bg-color-hover: transparent;
  --button-outline-white-text-color: #ffffff;
  --button-outline-white-text-color-hover: #ffffff;
  --button-outline-white-border-color: #f3f2e9;
  --button-outline-white-border-color-hover: #ffffff;

  //Arrow Buttons
  --button-arrow-color: #ffffff;
  --button-secondary-arrow-color: #1a1a1a;
  --button-arrow-bg-color: #7d0501;
  --button-arrow-color-hover: white;
  --button-arrow-bg-color-hover: #1a1a1a;
  --button-outline-arrow-color: #1a1a1a;
  --button-outline-arrow-bg-color: white;
  --button-outline-arrow-color-hover: #37451E;
  --button-outline-arrow-bg-color-hover: white;
}

.button,
a.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // @include primary-font-bold;
  @include fontsize(16 24);
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 10px;
  padding: 12px 20px;
  width: auto;
  min-width: 160px;
  height: auto;
  overflow: hidden;
  text-shadow: none;
  white-space: normal;

  &::before {
    content: none;
    opacity: 1;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    text-shadow: none;
  }

  span:not(.afp) {
    margin-right: 5px;
    color: var(--button-color);
    font-weight: 400;
  }

  .afp {
    margin-right: 10px;
    color: currentColor;

    &--round {
      position: relative;
      left: -10px;
      margin-right: 0;
    }
  }

  &--primary,
  &--secondary {

    &__arrow-left,
    &__arrow-right {

      &::before,
      &::after {
        content: '';
        position: absolute;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: var(--button-arrow-bg-color);
        color: var(--button-arrow-color);
        font-size: 13px;
        font-family: FontAwesome;
        font-weight: 900;
        top: 0;
        height: 100%;
        width: 40px;
        transition: all .25s ease-in-out;
        border-bottom: 0;
      }

      &:hover,
      &:focus {

        &::before,
        &::after {
          color: var(--button-arrow-color-hover);
          background: var(--button-arrow-bg-color-hover);
        }
      }
    }

    &__arrow-left {
      padding-left: 55px;

      &::before {
        content: '\f060';
        left: 0;
      }

      &::after {
        content: none;
      }
    }

    &__arrow-right {
      padding-right: 55px;

      &::before {
        content: none;
      }

      &::after {
        content: '\f061';
        right: 0;
      }
    }
  }

  &--primary {
    background: var(--button-bg-color);
    color: var(--button-text-color);

    &:hover,
    &:focus {
      background: var(--button-bg-color-hover);
      color: var(--button-text-color-hover);
    }
  }

  &--secondary {
    background: var(--button-secondary-bg-color);
    color: var(--button-secondary-text-color);

    &:hover,
    &:focus {
      background: var(--button-secondary-bg-color-hover);
      color: var(--button-secondary-text-color-hover);
    }

    &__arrow-left,
    &__arrow-right {

      &::before,
      &::after {
        color: var(--button-secondary-arrow-color);
        background: #dad8cf;
        ;
      }

      &:hover,
      &:focus {

        &::before,
        &::after {
          color: var(--button-secondary-arrow-color);
          background: #b19761;
        }
      }
    }
  }

  &--tertiary {
    background: var(--button-tertiary-bg-color);
    color: var(--button-tertiary-text-color);

    &:hover,
    &:focus {
      background: var(--button-tertiary-bg-color-hover);
      color: var(--button-tertiary-text-color-hover);
    }
  }

  &--primary-pill {
    background: var(--button-bg-color);
    color: var(--button-text-color);
    border-radius: 25px;

    &:hover,
    &:focus {
      background: var(--button-bg-color-hover);
      color: var(--button-text-color-hover);
    }
  }

  &--secondary-pill {
    background: var(--button-secondary-bg-color);
    color: var(--button-secondary-text-color);
    border-radius: 25px;

    &:hover,
    &:focus {
      background: var(--button-secondary-bg-color-hover);
      color: var(--button-secondary-text-color-hover);
    }
  }

  &--primary-outline {
    background: var(--button-outline-bg-color);
    color: var(--button-outline-text-color);
    border-radius: 10px;
    border: 1px solid var(--button-outline-border-color);

    &:hover,
    &:focus {
      background: var(--button-outline-bg-color-hover);
      color: var(--button-outline-text-color-hover);
      border-color: var(--button-outline-border-color-hover);
    }

    &__arrow-left,
    &__arrow-right,
    &__arrow-left-white,
    &__arrow-right-white {

      &::before,
      &::after {
        content: '';
        position: absolute;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: var(--button-outline-arrow-bg-color);
        color: var(--button-outline-arrow-color);
        font-size: 13px;
        font-family: FontAwesome;
        font-weight: 900;
        top: 0;
        height: 100%;
        width: 40px;
        transition: all .25s ease-in-out;
        border-bottom: 0;
      }

      &:hover,
      &:focus {

        &::before,
        &::after {
          color: var(--button-outline-arrow-color-hover);
          border-color: var(--button-outline-border-color-hover);
        }
      }
    }

    &__arrow-left {
      padding-left: 55px;

      &::before {
        content: '\f060';
        left: 0;
        background: transparent;
        border-right: 1px solid var(--button-outline-border-color);
      }

      &::after {
        content: none;
        background: transparent;
      }

      &:hover,
      &:focus {
        &::before {
          background: transparent;
          border-right: 1px solid var(--button-outline-border-color-hover);

        }

        &::after {
          display: none;
        }
      }
    }

    &__arrow-right {
      padding-right: 55px;

      &::before {
        content: none;
        background: transparent;
      }

      &::after {
        content: '\f061';
        right: 0;
        background: transparent;
        border-left: 1px solid var(--button-outline-border-color);
      }

      &:hover,
      &:focus {
        &::before {
          display: none;
        }

        &::after {
          content: '\f061';
          background: transparent;
          border-left: 1px solid var(--button-outline-border-color-hover);

        }
      }
    }

    &__arrow-right-white,
    &__arrow-left-white {
      background: transparent;
      color: #ffffff;
      border-color: #D6D6D6;
    }

    &__arrow-right-white {
      padding-right: 55px;

      &::before {
        display: none;
        background: transparent;
      }

      &::after {
        content: '\f061';
        right: 0;
        background: transparent;
        color: #D6D6D6;
        border-left: 1px solid #ffffff;
      }

      &:hover,
      &:focus {
        color: #ffffff;
        border-color: #ffffff;

        &::after {
          background: transparent;
          color: #ffffff;
          border-left: 1px solid #ffffff;

        }
      }
    }

    &__arrow-left-white {
      padding-left: 55px;

      &::before {
        content: '\f060';
        left: 0;
        background: transparent;
        color: #D6D6D6;
        border-right: 1px solid #ffffff;
      }

      &::after {
        display: none;
        background: transparent;
      }

      &:hover,
      &:focus {
        color: #ffffff;
        border-color: #ffffff;

        &::before {
          background: transparent;
          color: #ffffff;
          border-right: 1px solid #ffffff;
        }
      }
    }
  }

  &--primary-outline-white {
    background: var(--button-outline-white-bg-color);
    color: var(--button-outline-white-text-color);
    border-radius: 10px;
    border: 1px solid var(--button-outline-white-border-color);

    &:hover,
    &:focus {
      background: var(--button-outline-white-bg-color-hover);
      color: var(--button-outline-white-text-color-hover);
      border-color: var(--button-outline-white-border-color-hover);
    }
  }

  &--primary-pill-outline {
    background: var(--button-outline-bg-color);
    color: var(--button-outline-text-color);
    border-radius: 25px;
    border: 1px solid var(--button-outline-border-color);

    &:hover,
    &:focus {
      background: var(--button-outline-bg-color-hover);
      color: var(--button-outline-text-color-hover);
      border-color: var(--button-outline-border-color-hover);
    }
  }

  &--primary-pill-outline-white {
    background: var(--button-outline-white-bg-color);
    color: var(--button-outline-white-text-color);
    border-radius: 25px;
    border: 1px solid var(--button-outline-white-border-color);

    &:hover,
    &:focus {
      background: var(--button-outline-white-bg-color-hover);
      color: var(--button-outline-white-text-color-hover);
      border-color: var(--button-outline-white-border-color-hover);
    }
  }

  &--call {
    &::before {
      content: '';
    }
  }

  &--helpDesk {
    &::before {
      content: '';
    }
  }
}

.button-list {
  padding-top: 20px;
  padding-bottom: 35px;

  li {
    .button {
      margin-bottom: 15px;
    }

    &:last-of-type {
      .button {
        margin-bottom: 0;
      }
    }
  }
}
:root {
  //Light Block Container
  --bc-background-color: #f3f2e9;;
  --bc-text-color: #1a1a1a;

  //Dark Block Container
  --bc-dark-background-color: #1a1a1a;
  --bc-dark-text-color: #efefef;

  .dark-mode {
    //Light Block Container
    --bc-background-color: #efefef;
    --bc-text-color: #1a1a1a;

    //Dark Block Container
    --bc-dark-background-color: #1a1a1a;
    --bc-dark-text-color: #efefef;
  }
}

.block-container {
  position: relative;
  padding: 50px 30px 60px;
  overflow: hidden;
  z-index: 0;

  @include large-up {
    padding: 80px 55px 75px;
  }

  @at-root body.subpage.sidebar-layout .block-container {
    margin: 0 -30px;

    @include large-up {
      margin: 0 -80px;
      padding: 60px 80px 55px;
    }
  }

  >div:not(.col-2):not(.col-3):not(.col-4):not(.col-5):not(.col-6) {
    @include width-restrict(35, 60, $contentwidth);
    margin-left: auto;
    margin-right: auto;
  }

  .columns {
    margin: auto;
  }

  @at-root body.subpage.fullwidth .block-container,
  body.homepage & {
    margin-left: calc(50% - 50vw);
    width: 100vw;

    >div {
      @include width-restrict(35, 60, $contentwidth);
    }
  }

  &.light {
    background: var(--bc-background-color);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
      color: var(--bc-text-color);

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &.dark {
    background: var(--bc-dark-background-color);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
      color: var(--bc-dark-text-color);

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &[data-arx-type] {
    position: relative;
    outline: rgba(0, 88, 251, .2) dashed;
    margin: 10px 0 20px !important;
    max-width: 100% !important;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 4;
      display: inline-block;
      padding: 2PX;
      font-size: 12PX;
      font-weight: 700;
      text-transform: uppercase;
      background: rgba(0, 88, 251, .04);
      border: 0.0625rem dashed rgba(0, 88, 251, .2);
      border-top: 0;
      border-right: 0;
      content: "block-container";
    }
  }
}
// pixel to rem
// @include rem-calc(10px 10px 10px 10px);

@use 'sass:math';


//---------- Old mixins from components/mixins ----------//
@mixin box-shadow($val...) {
	box-shadow: ($val);
	-moz-box-shadow: ($val);
	-webkit-box-shadow: ($val);
}

@mixin border-radius($val) {
	border-radius: ($val);
}

@mixin transition($val...) {
	-webkit-transition: ($val);
	-o-transition: ($val);
	transition: ($val);
}

@mixin translateX($val...) {
	-webkit-transform: translateX($val);
	-ms-transform: translateX($val);
	-o-transform: translateX($val);
	transform: translateX($val);
}

@mixin translateY($val...) {
	-webkit-transform: translateY($val);
	-ms-transform: translateY($val);
	-o-transform: translateY($val);
	transform: translateY($val);
}

@mixin translate3d($val1,$val2,$val3) {
	-webkit-transform: translate3d($val1,$val2,$val3);
	-ms-transform: translate3d($val1,$val2,$val3);
	-o-transform: translate3d($val1,$val2,$val3);
	transform: translate3d($val1,$val2,$val3);
}

@mixin rotate($val){
	-webkit-transform: rotate($val);
	-moz-transform: rotate($val);
	-ms-transform: rotate($val);
	-o-transform: rotate($val);
	transform: rotate($val);
}

@mixin scale($val){
	-webkit-transform: scale($val);
	-moz-transform: scale($val);
	-ms-transform: scale($val);
	transform: scale($val);
}


//---------- Blog Grid Item Size Builder ----------//

@function blog-grid-item-width($container, $columns, $margin) {
	@return floor( math.div( $container - ( $margin * ( $columns - 1 ) ) , $columns) );
}

@function strip-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

@function rem-calc($values, $base: $base-font-size) {
  $rem-values: ();
  $count: length($values);

  @if $base ==null {
    $base: 100%;
  }

  @if unit($base)=='%' {
    $base: math.div($base, 100%) * 16px;
  }

  @if $count ==1 {
    @return -ws-to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, -ws-to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}

@function rc($values, $base: $base-font-size) {
  $rem-values: ();
  $count: length($values);

  @if $base ==null {
    $base: 100%;
  }

  @if unit($base)=='%' {
    $base: math.div($base, 100%) * 16px;
  }

  @if $count ==1 {
    @return -ws-to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, -ws-to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}

@function -ws-to-rem($value, $base: null) {
  @if type-of($value) !='number' {
    @warn inspect($value)+'was passed to rem-calc(), which is not a number.';

    @return $value;
  }

  @if unit($value) !='rem' {
    $value: math.div(strip-unit($value), strip-unit($base)) * 1rem;
  }

  @if $value ==0rem {
    $value: 0;
  }

  @return $value;
}

// transition mixin
// @include transition(all, 0.2s, ease);
@mixin transition($value) {
  transition: $value;
}

// input placeholders
// @include placeholder {}
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

// viewport width based font size and line height
// viewport width, font-size, line-height
// @include vwfontsize(640px 16px);
@mixin vwfontsize($value) {
  font-size: $fontsize;
  line-height: $lineheight;

  $viewportwidth: nth($value, 1);
  $vw_value: ($viewportwidth * 0.01) * 1;
  $fontsize: math.div(nth($value, 2), $vw_value)+vw;
  $lineheight: $fontsize;

  @if length($value)>2 {
    $fontsize: math.div(nth($value, 2), $vw_value)+vw;
    $lineheight: math.div(nth($value, 3), $vw_value)+vw;
  }

  @else {
    $fontsize: math.div(nth($value, 2), $vw_value)+vw;
    $lineheight: nth($value, 2) * 1.5;
  }
}

// px to rem font size and line height
// @include fontsize(45); OR @include fontsize(45 45); OR @include fontsize(45 45 40);
// the letter-spacing value is the exact value seen in the photoshop letter tracking field
@mixin fontsize($size) {
  $fontsize: nth($size, 1);
  $lineheight: $fontsize;
  $letterspacing: normal;

  @if length($size)==3 {
    $lineheight: nth($size, 2);
    $letterspacing: nth($size, 3) * 0.001+em;
  }

  @else if length($size)==2 {
    $lineheight: nth($size, 2);
    $letterspacing: normal;
  }

  @else if length($size)==1 {
    $lineheight: nth($size, 1) * 1.5;
    $letterspacing: normal;
  }

  font-size: math.div($fontsize, 1)+px;
  line-height: math.div($lineheight, 1)+px;
  font-size: math.div($fontsize, 16)+rem;
  line-height: math.div($lineheight, 16)+rem;
  letter-spacing: $letterspacing;
}

// calculate letter spacing from photoshop kerning value
// @include letterspacing(40);
@mixin letterspacing($letterspacing) {
  letter-spacing: $letterspacing * 0.001+em;
}

// media query shorthand
// @include mq ($query1 $query2 $query3){ content }
@mixin mq($query) {
  @if length($query)==1 {
    $query1: nth($query, 1);

    @if $query1 =='portrait' {
      @media screen and (orientation: portrait) {
        @content;
      }
    }

    @else if $query1 =='landscape' {
      @media screen and (orientation: landscape) {
        @content;
      }
    }

    @else {
      @media screen and (min-width: $query1 + px) {
        @content;
      }
    }
  }

  @else if length($query)==2 {
    $query1: nth($query, 1);
    $query2: nth($query, 2);

    @if $query2 =='portrait' {
      @media screen and (min-width: $query1 + px) and (orientation: portrait) {
        @content;
      }
    }

    @else if $query2 =='landscape' {
      @media screen and (min-width: $query1 + px) and (orientation: landscape) {
        @content;
      }
    }

    @else {
      @media screen and (min-width: $query1 + px) and (max-width: $query2 - 1 + px) {
        @content;
      }
    }
  }

  @else if length($query)==3 {
    $query1: nth($query, 1);
    $query2: nth($query, 2);
    $query3: nth($query, 3);

    @if $query3 =='portrait' {
      @media screen and (min-width: $query1 + px) and (max-width: $query2 - 1 + px) and (orientation: portrait) {
        @content;
      }
    }

    @else if $query3 =='landscape' {
      @media screen and (min-width: $query1 + px) and (max-width: $query2 - 1 + px) and (orientation: landscape) {
        @content;
      }
    }

    @else {
      @media screen and (min-width: $query1 + px) and (max-width: $query2 + px) {
        @content;
      }
    }
  }
}

// default media queries
// @include small-only { content }
@mixin small-only {
  @media (max-width: #{$medium - 1px}) {
    @content;
  }
}

@mixin small-only {
  @media (min-width: 0) and (max-width: #{$medium - 1px}) {
    @content;
  }
}

@mixin medium-up {
  @media (min-width: #{$medium}) {
    @content;
  }
}

@mixin medium-only {
  @media (min-width: #{$medium}) and (max-width: #{$large - 1px}) {
    @content;
  }
}

@mixin large-up {
  @media (min-width: #{$large}) {
    @content;
  }
}

@mixin large-only {
  @media (min-width: #{$large}) and (max-width: #{$xlarge - 1px}) {
    @content;
  }
}

@mixin xlarge-up {
  @media (min-width: #{$xlarge}) {
    @content;
  }
}

@mixin xlarge-only {
  @media (min-width: #{$xlarge}) and (max-width: #{$xxlarge - 1px}) {
    @content;
  }
}

@mixin xxlarge-up {
  @media (min-width: #{$xxlarge}) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

// target retina/high dpi screens
// @include retina { content }
@mixin retina {

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    @content;
  }
}

// position element offscreen
// @include offscren { content }
@mixin offscreen {
  position: absolute;
  top: -9999em;
  left: -9999em;
}

// visible to screen readers only
// @include sr-only { content }
@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  color: black;
  background: white;
  border: 0;
}

// background image mixin
// @include asset(#ffffff, 'IMAGE.png', center center, cover, no-repeat);
@mixin asset($color, $filename, $position: 0 0, $size: cover, $repeat: no-repeat) {
  background: $color url(#{$assets}#{$filename}) math.div($position, $size) $repeat;
}

@mixin width-restrict($outer-margin-mobile: 40, $outer-margin-desktop: 80, $standard-width: $contentwidth) {
  $outer-margin-mobile: $outer-margin-mobile * 1px;
  $outer-margin-desktop: $outer-margin-desktop * 1px;

  width: auto;
  max-width: calc(100% - $outer-margin-mobile);
  margin: 0 auto;

  @if type-of($standard-width)==number {
    width: $standard-width * 1px;
  }

  @media all and (min-width: $medium) {
    max-width: calc(100% - $outer-margin-desktop);
  }
}
:root {
  //Highlighted Text Block
  --highlighted-text-block-bg: #f3f2e9;
  --highlighted-text-block-text-color: #1a1a1a;
  --highlighted-text-block-link: #1a1a1a;
  --highlighted-text-block-link-hover: #1a1a1a;
  --highlighted-text-block-border-color: #F15352;

  //Highlighted Text Block - Alternate Version
  --highlighted-text-block-border-color-secondary: #d4b677;

  .dark-mode {
    //Highlighted Text Block
    --highlighted-text-block-bg: #f2f2f2;
    --highlighted-text-block-text-color: #1a1a1a;
    --highlighted-text-block-link: #1a1a1a;
    --highlighted-text-block-link-hover: #1a1a1a;
    --highlighted-text-block-border-color: #1a1a1a;

    //Highlighted Text Block - Alternate Version
    --highlighted-text-block-border-color-secondary: #cccccc;
  }
}

.highlighted-text,
.highlighted-text-block {
  position: relative;
  margin: 0 auto 25px;
  border-radius: 10px;
  background-color: var(--highlighted-text-block-bg);
  padding: 25px 20px;
  border-left: 30px solid var(--highlighted-text-block-border-color);

  @include medium-up {
    padding: 30px 40px;
    border-left-width: 40px;
  }

  &.secondary-color {
    border-color: var(--highlighted-text-block-border-color-secondary);
  }

  //&__heading
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 22px;
    line-height: 28px;
    color: var(--highlighted-text-block-text-color);
  }

  //&__content
  p,
  ul,
  ol {
    font-size: 16px;
    line-height: 24px;
    color: var(--highlighted-text-block-text-color);

    // @include primary-font-bold;

    @include medium-up {
      font-size: 22px;
      line-height: 28px;
    }

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
:root {
  //Content Calls to Action (cta-banner)
  --cta-bg-color: #f3f2e9;
  --cta-text-color: #1a1a1a;
  --cta-button-bg-color: #1a1a1a;
  --cta-button-text-color: white;
  --cta-button-border-color: white;
  --cta-button-bg-color-hover: white;
  --cta-button-text-color-hover: #1a1a1a;

  .dark-mode {
    //Content Calls to Action (cta-banner)
    --cta-bg-color: #1a1a1a;
    --cta-text-color: white;
    --cta-button-bg-color: #1a1a1a;
    --cta-button-text-color: white;
    --cta-button-border-color: white;
    --cta-button-bg-color-hover: white;
    --cta-button-text-color-hover: #1a1a1a;
  }
}

.cta-banner-image {
  display: block;
  width: 100%;
  margin: 15px 0;

  @include medium-up {
    display: inline-flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__image {
    border-radius: 10px;
    overflow: hidden;

    @include medium-up {
      min-width: 190px;
      max-width: 190px;
      margin-right: 10px;
    }

    figure {
      margin: 0 auto 15px;
      overflow: hidden;
      border-radius: 10px;

      img {
        object-fit: cover;
        width: 100%;
        min-height: 140px;
        max-height: 140px;
      }
    }
  }

  .cta-banner {
    margin: 0;
    min-height: 140px;
  }
}

.cta-banner {
  display: block;
  background: var(--cta-bg-color);
  width: 100%;
  border-radius: 10px;
  margin: 15px 0;
  padding: 36px 40px;

  @include medium-up {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 48px 60px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--cta-text-color);
  }

  p {
    font-size: 22px;
    line-height: 30px;
    color: var(--cta-text-color);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--textured {
    background-image: url('/themes/canvas-template/assets/dist/images/Texture-BG__small.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  &__button {
    @include medium-up {
      margin-left: 60px;
    }
  }
}
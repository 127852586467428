:root {
  //Headings
  --heading-color: #1a1a1a;
  --heading-color-secondary: #737373;
  --heading-underline-color: #cccccc;

  //Paragraphs
  --paragraph-text-color: #1a1a1a;

  //Horizontal Rule
  --hr-color: #e3e3e3;

  .dark-mode {
    //Headings
    --heading-color: #f2f2f2;
    --heading-underline-color: #ccc;

    //Paragraphs
    --paragraph-text-color: #f2f2f2;
  }
}

/*-----------------------------------------------------------------------------------
	typography.scss
-----------------------------------------------------------------------------------*/

dl,
dt,
dd,
ol,
ul,
li {
  margin: 0;
  padding: 0;
}

.clear {
  display: block;
  clear: both;
  overflow: hidden;
  width: 100%;
  height: 0;
  font-size: 0;
  line-height: 0;
}

::selection {
  background: $theme-color;
  color: #ffffff;
  text-shadow: none;
}

::-moz-selection {
  background: $theme-color;
  /* Firefox */
  color: #ffffff;
  text-shadow: none;
}

::-webkit-selection {
  background: $theme-color;
  /* Safari */
  color: #ffffff;
  text-shadow: none;
}

:active,
:focus {
  outline: none !important;
}


/* ----------------------------------------------------------------
	Typography
-----------------------------------------------------------------*/


body {
  max-width: $sitewidth;
  color: $body-color;
  font-size: $font-size-base;
  font-family: $body-font;
  line-height: $line-height-base;
  margin: 0 auto;
  overflow-x: hidden;
}

a {
  color: #F15352;

  &:hover {
    color: #222222;
  }

  img {
    border: none;
  }
}

img {
  max-width: 100%;
}

iframe {
  border: none !important;
}

/* ----------------------------------------------------------------
	Basic Layout Styles
-----------------------------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px 0;
  color: $heading-color;
  font-weight: 600;
  font-family: $heading-font;
  line-height: $line-height-base;

  &.bordered {
    border-bottom: 2px solid $theme-color-light;
  }

  &.underline {
    display: block;
    width: 100%;
    border-bottom: 1px solid var(--heading-underline-color);
  }

  &.text-shadow {
    text-shadow: 0 3px 6px rgba(black, .16);
  }

  >span:not(.nocolor) {
    color: $theme-color;
  }
}

h5,
h6 {
  margin-bottom: 20px;
}

h1 {
  @include fontsize(36 36);

  &.large {
    @include fontsize(60 60);
  }
}

h2 {
  @include fontsize(30 30);

  &.large {
    margin-bottom: 30px;
    @include fontsize(36 36);
  }

  &.underline {
    padding-bottom: 15px;
    margin-bottom: 30px;
  }
}

h3 {
  font-size: $font-size-h3;
  @include fontsize(24 28);

  &.large {
    margin-bottom: 30px;
    @include fontsize(30 36);
  }

  &.underline {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

h4 {
  @include fontsize(18 24);
  font-weight: 600;

  &.large {
    margin-bottom: 15px;
    @include fontsize(28 34);
  }

  &.underline {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

h5 {
  @include fontsize(18 24);
  // font-weight: bold;

  &.large {
    margin-bottom: 15px;
    @include fontsize(28 34);
  }

  &.underline {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

h6 {
  @include fontsize(16 18);

  &.large {
    margin-bottom: 15px;
    @include fontsize(24 30);
  }

  &.underline {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

p {
  margin-bottom: 20px;
  @include fontsize(18 28);
  font-weight: 300;

  &.small-text,
  small {
    @include fontsize(14 24);
  }

  &.medium-text {
    @include fontsize(20 28);

    @include medium-up {
      @include fontsize(22 32);
    }
  }

  &.large-text {
    @include fontsize(22 30);

    @include medium-up {
      @include fontsize(24 34);
    }
  }
}

#wrapper {
  position: relative;
  float: none;
  margin: 0 auto;
  width: 1220px;
  background-color: #ffffff;

  @include box-shadow(0 0 10px rgba(0, 0, 0, 0.1));
}
.content {
  img {
    margin: 0;

    &[style*='left'] {
      margin-right: 20px;
    }

    &[style*='right'] {
      margin-left: 20px;
    }
  }
}

.btn-hidden-text {
  display: block;
  overflow: hidden;
  margin: 0;
  height: 0;
  font-size: 0;
}

.homepage #main {
  padding-top: 60px;

  h1 {
    font-size: 37px;
    color: #605e58;
    border-bottom: 1px solid #605e58;
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-weight: regular;

    +p {
      font-size: 20px;
      color: #403c39;
      line-height: 28px;
    }
  }

  h2 {
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #605e58;
    font-weight: bold;

    @at-root .homepage #main .image-content-banner .image-content-banner__text-content {
      h2 {
        color: #ffffff;
      }
    }
  }

  .image-full,
  .video-full {
    margin-top: 41px;
    margin-bottom: 41px;
    width: calc(100vw - 17px);
    transform: translateX(-50%);
    left: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-full {
    background: #f3f2e9;
    max-width: none;
  }

  .video-full {
    background: #373734;
    padding: 20px;

    @media screen and (min-width: 968px) {
      padding: 20px 0;
    }

    >div {
      max-width: 1170px;

      @media screen and (min-width: 968px) {
        display: flex;
        align-items: center;

        >* {
          width: 50%;
        }
      }

      h2 {
        font-size: 20px;
        text-transform: uppercase;
        color: #d4b677;
        line-height: 24px;
      }

      p {
        font-size: 18px;
        color: #fff;
        line-height: 24px;
        margin-right: 30px;
      }

      img {
        width: 100%;
        margin: 0 auto;
        max-width: 554px;
        display: block;
        margin-bottom: 40px;

        @media screen and (min-width: 968px) {
          margin-bottom: 0;
        }
      }
    }
  }
}

// h2 {
//   &.red {
//     margin-bottom: 11px;
//     color: #F15352;
//     font-size: 18px;
//   }
// }

// h3 {
// margin-bottom: 11px;
// color: #000000;
// font-size: 22px;
// font-family: 'raleway', sans-serif;
// }

#content .content-wrap {
  @media (max-width: 980px) {
    padding: 0;
  }
}

.stretched #wrapper {
  height: 85.75vh;
}

#slider {
  height: auto !important;

  .container {
    width: 100%;
  }

  picture {
    width: 100vw;
    height: auto;

    img {
      margin-left: -15px;
      max-width: none;
      width: 100vw;
    }
  }
}

.subpage-banner {
  overflow: hidden;
  width: 100%;

  img {
    position: relative;
    left: 50%;
    width: 100%;
    height: auto;
    transform: translateX(-50%);
  }

  p {
    margin: 0;
  }
}

.page-header {
  margin-top: 0;
  padding-top: 47px;
  padding-bottom: 30px;
  border-bottom: 0;

  h1 {
    margin: 0;
  }
}

#footer.dark {
  margin-top: 0 !important;
}

#main.vs-content-container {
  padding-bottom: 50px;
  min-height: 100%;
  overflow-x: hidden;
  // background: #e7e3d2;
}

.block {
  display: block;
  float: none !important;
  width: auto !important;
}

.content-form {

  input,
  textarea {
    display: block;
    width: 100%;

    &[type='submit'] {
      width: auto;
    }
  }
}

.content-form {
  label {
    margin-top: 10px;
    margin-bottom: 0;
  }

  input[type='text'],
  textarea,
  select,
  input[type='numeric'] {
    display: block;
    padding: 8px 14px;
    width: 100%;
    height: auto;
    border: 2px solid #dddddd;
    border-radius: 0 !important;
    background-color: #ffffff;
    background-image: none;
    color: #555555;
    font-size: 15px;
    line-height: 1.42857143;
    transition: border-color ease-in-out 0.15s;
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: inline;
    margin: 0 5px 2px 5px;
    width: auto;
    vertical-align: middle;
  }

  input[type='submit'] {
    margin-left: 0;
  }

  span.seats-available {
    position: absolute;
    top: 12px;
    right: 20px;
    font-size: 11px;
  }

  .pmt-total p {
    font-weight: 800;
    font-size: 1.25em;
  }

  .pmt-check p {
    margin: 20px;
    white-space: pre;
    font-weight: 800;
    font-size: 1.25em;
  }
}

.denied-list {
  position: relative;
  margin: 50px 0 20px;
  padding: 0;
  list-style: none;
  font-size: 22px;
  line-height: 1.5em;

  &:after {
    position: absolute;
    top: 81%;
    left: 230px;
    z-index: 0;
    width: 500px;
    height: 230px;
    background: transparent url(/themes/canvas-template/assets/dist/images/uhlmann/Pages/flour-scoop.png) no-repeat left center;
    background-size: contain;
    content: '';
    transform: translateY(-50%);

    @media (max-width: 768px) {
      content: none;
    }
  }

  li {
    margin-left: 0;
    padding-bottom: 0;
    padding-left: 11px;

    &:before {
      position: relative;
      left: -10px;
      margin-left: 0;
      padding-left: 0;
      color: #F15352;
      content: '\f05e';
      font-family: FontAwesome;
    }
  }

  +p+h2 {
    position: relative;
    z-index: 1;
  }
}

p.drop-cap:first-letter {
  float: left;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 3px;
  color: #000000;
  font-size: 80px;
  font-family: 'orbe', serif;
  line-height: 60px;
}

.sticky-header {
  #primary-menu {
    >ul>li:last-child a {
      margin: 0;
      line-height: 15px;
    }
  }
}

.distributors-list {
  @media (min-width: 768px) {
    float: left;
    width: 50%;
  }
}

.text-right {
  text-align: right !important;
}

.vs-content-container,
.page-header {
  clear: both;
}

.entry-meta {
  max-height: 30px;
}

.entry-content,
.postcontent {
  img {
    margin: 0 20px 20px;

    &[style*='left'] {
      margin-left: 0;
    }

    &[style*='right'] {
      margin-right: 0;
    }
  }
}

#logo a[class*='-logo'] {
  float: left;
}

@media (max-width: 768px) {
  #logo a.standard-logo {
    position: relative;
    top: 20px;
    left: -15px;
    display: block;

    img {
      height: 60px;
    }
  }
}

a.feed-america-logo {
  margin-left: 20px;

  img {
    max-height: 60px;
  }
}

.mobile-button {
  display: block;
  margin: 20px;
  text-align: center;
}

iframe {
  max-width: 100%;
}

#footer {
  margin-top: 40px;
}

dl,
dt,
dd,
ol,
ul,
li {
  margin-left: 30px;
  padding-left: 10px;
}

#dlRetailers {
  display: none;
  clear: both;
  margin: 0 20px;
  padding: 20px 20px 0 20px;
  width: 45%;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0 0 5px 5px;
  background: #ffffff;
  list-style: none;
  vertical-align: top;

  &.visible {
    display: inline-block;
  }

  li {
    margin: 0 0 40px 0;
    padding: 0;
  }

  @media (max-width: 1200px) {
    margin: 0;
    width: 100%;
  }
}

.map-container {
  display: inline-block;
  float: none;
  width: 51%;

  @media (max-width: 1200px) {
    width: 100%;
  }
}

@media (min-width: 980px) {
  .hide-for-large-up {
    display: none;
  }
}

@media (max-width: 980px) {
  div[class*='col-'] {
    height: auto !important;
  }
}

@media (max-width: 991px) {
  #primary-menu-trigger {
    right: 60px;
    left: auto;
  }
}

@media (max-width: 479px) {
  #primary-menu-trigger {
    right: 30px;
  }

  #logo a.standard-logo img {
    top: 25px;
    left: 0;
    height: 50px;
  }
}

.heading-block {
  >span:not(.before-heading) {
    margin-top: 30px;
    max-width: 950px;
    font-size: 22px;
  }
}

#copyrights {
  .col_half.tright {
    margin-right: 0;

    ul {
      margin: 0;
      list-style: none;

      li {
        display: inline;
      }
    }
  }
}

.col_one_fourth:nth-child(4n) {
  margin-right: 0;
}

.find-retailer-search {
  margin-bottom: 110px;

  .input-group-addon {
    background: #d8d4c4;
  }

  .form-control {
    border-right: 0;
    background: rgba(255, 255, 255, 0.5);
  }

  .input-group-btn {
    border-left: 0;
    background: transparent;

    .btn.btn-default {
      border-left: 0;
      background: transparent;
    }
  }
}

ul {
  li {
    margin-left: 15px;
    padding-bottom: 11px;
    padding-left: 20px;
  }
}

.global-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 5px 0;
  width: 100%;
  background: #333333;
  color: #ffffff;
  font-size: 14px;
  line-height: 14px;
  max-width: $sitewidth;
  margin: 0 auto;
  // font-size: 0.75em;
  // line-height: 0.75em;

  li {
    padding-bottom: 0;
  }
}

#primary-menu>ul>li {
  padding-bottom: 0;
}

.btn-dark {
  position: relative;
  display: inline-block;
  float: right;
  margin-top: -60px;
  padding: 15px 30px;
  width: 200px;
  outline: none;
  border: none;
  background: none;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: inherit;
  font-family: inherit;
  transition: all 0.3s;

  @media (max-width: 768px) {
    float: none;
    margin: 10px 0;
  }
}

.btn-dark:after {
  position: absolute;
  z-index: -1;
  content: '';
  transition: all 0.3s;
}

.btn-icon {
  overflow: hidden;
  background: #337ab7;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  backface-visibility: hidden;
}

.btn-icon span {
  display: inline-block;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  backface-visibility: hidden;
}

.btn-icon:before {
  position: absolute;
  width: 30%;
  height: 100%;
  background: #1b578b;
  font-size: 150%;
  line-height: 2.5;
  transition: all 0.3s;
}

.btn-download:hover span {
  color: #ffffff;
  transform: translateX(25%);
}

.btn-download:before {
  top: 0;
  left: -100%;
}

.btn-download:hover:before {
  left: 0;
}

.icon-reveal:before {
  color: #ffffff;
  content: '\e62a';
  text-align: center;
  font-family: 'font-icons';
}

div[id*='pnlCaptcha'] {
  margin: 20px 0;
}

#main .fbox-icon a {
  text-decoration: none !important;
}

.feature-box {
  p {
    color: #000000;
  }
}

.image-button {
  border: 0;
  background: transparent;
}

#main a.list-group-item {
  margin: 0 0;
  padding: 14px 0;
  border: 0;
  border-bottom: 1px solid #ffffff;
  background: transparent;
  color: #000000;
  text-decoration: none !important;
  font-weight: 600;
  font-size: 16px;

  &:before {
    margin-right: 5px;
    color: #b0403d;
    content: '\f1c1';
    vertical-align: text-bottom;
    font-family: FontAwesome;
  }
}

.lrs_warning {
  position: relative;
  z-index: 1001;
}

body.search table.table.table-striped.table-bordered.table-hover {
  border: 0 !important;

  >tbody {
    border: 0;
    background: transparent;

    >tr {
      &:first-child {
        >th {
          background: #333333;
          color: #ffffff;
        }

        &:hover {
          background: #333333;
          color: #ffffff;
        }
      }

      &:hover {
        background: transparent !important;
      }

      >td {
        border: 0 !important;
        background: transparent !important;

        &:hover {
          background: transparent !important;
        }
      }

      &:nth-child(odd) {
        border: 0;
        background: transparent !important;

        >td {
          border: 0 !important;
          background: transparent !important;
        }

        &:hover {
          >td {
            background: transparent !important;
          }
        }
      }
    }
  }
}

.faqs.home-bakers {
  .icon-question-sign {
    color: #d0234c;
  }
}

.product-display {
  display: flex;
  align-items: flex-start;

  .product-image {
    max-width: 190px;
    margin-right: 20px;
    min-width: 190px;
  }
}
:root {
  //Banner General Text
  --gutter: $gutter;
}

.ag {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -#{var(--gutter)};

  &__column {
    position: relative;
    margin: 0 0 0 var(--gutter);
    box-sizing: border-box;

    &--order-first {
      order: -1;
    }

    &--one-sixth {
      width: 100%;

      @media all and (min-width: $medium) {
        width: calc(100% / 2 - #{var(--gutter)});
      }

      @media all and (min-width: $large) {
        width: calc(100% / 3 - #{var(--gutter)});
      }

      @media all and (min-width: $xlarge) {
        width: calc(100% / 6 - #{var(--gutter)});
      }
    }
    &[class*='1/5'],
    &--one-fifth,
    &--twenty-percent {
      width: 100%;

      @media all and (min-width: $medium) {
        width: calc(100% / 2 - #{var(--gutter)});
      }

      @media all and (min-width: $large) {
        width: calc(100% / 3 - #{var(--gutter)});
      }

      @media all and (min-width: $xlarge) {
        width: calc(100% / 5 - #{var(--gutter)});
      }
    }
    &[class*='1/4'],
    &--one-quarter,
    &--twentyfive-percent {
      width: 100%;

      @media all and (min-width: $medium) {
        width: calc(100% / 2 - #{var(--gutter)});
      }

      @media all and (min-width: $large) {
        width: calc(100% / 4 - #{var(--gutter)});
      }
    }
    &[class*='1/3'],
    &--one-third,
    &--thirtythree-percent {
      width: 100%;

      @media all and (min-width: $medium) {
        width: calc(100% / 2 - #{var(--gutter)});
      }

      @media all and (min-width: $large) {
        width: calc(100% / 3 - #{var(--gutter)});
      }
    }
    &[class*='2/5'],
    &--two-fifths,
    &--forty-percent {
      width: 100%;

      @media all and (min-width: $medium) {
        width: calc(100% / 2 - #{var(--gutter)});
      }

      @media all and (min-width: $large) {
        width: calc(100% / 5 * 2 - #{var(--gutter)});
      }
    }
    &[class*='1/2'],
    &--one-half,
    &--fifty-percent {
      width: 100%;

      @media all and (min-width: $medium) {
        width: calc(100% / 2 - #{var(--gutter)});
      }
    }
    &[class*='3/5'],
    &--three-fifths,
    &--sixty-percent {
      width: 100%;

      @media all and (min-width: $medium) {
        width: calc(100% / 5 * 3 - #{var(--gutter)});
      }
    }
    &[class*='2/3'],
    &--two-thirds {
      width: 100%;

      @media all and (min-width: $medium) {
        width: calc(100% / 3 * 2 - #{var(--gutter)});
      }
    }
    &[class*='3/4'],
    &--three-quarters,
    &--seventyfive-percent {
      width: 100%;

      @media all and (min-width: $medium) {
        width: calc(100% / 4 * 3 - #{var(--gutter)});
      }
    }
    &[class*='5/6'],
    &--five-sixths {
      width: 100%;

      @media all and (min-width: $medium) {
        width: calc(100% / 6 * 5 - #{var(--gutter)});
      }
    }
    &[class*='1/1'],
    &--full {
      width: 100%;
    }
  }
}
//-------------------- Base Colors --------------------//
$theme-color: #F15352;
$theme-color-light: #d7e7f6;
$body-color: #000000;
$heading-color: #333333;
//-------------------- Line Height Variables --------------------//
$line-height-base: 1.35;
$line-height-content: 1.4;
//-------------------- Margins --------------------//
$margin-base: 50px;
$margin-small: 30px;
$margin-big: 80px;
//-------------------- Font Families --------------------//
$body-font: 'Lato',
  sans-serif;
$heading-font: 'Raleway',
  sans-serif;
$secondary-font: 'Crete Round',
  serif;
//-------------------- Font Sizes --------------------//
$font-size-base: 18px;
$font-size-h1: 36px;
$font-size-h2: 30px;
$font-size-h3: 24px;
$font-size-h4: 18px;
$font-size-h5: $font-size-base;
$font-size-h6: 12px;
$success: #0ba05c;
$info: #053a8a;
$warning: #ffca00;
$danger: #a94442;
//-------------------- Layouts --------------------//
$postcontent: 860px;
$postcontent-margin: 40px;
$sidebar: 1140px - ($postcontent + $postcontent-margin);
$bothsidebar: 1140px - (($sidebar * 2) + ($postcontent-margin * 2));
$postcontent-md: 690px;
$postcontent-margin-md: 40px;
$sidebar-md: 940px - ($postcontent-md + $postcontent-margin-md);
$bothsidebar-md: 940px - (($sidebar-md * 2) + ($postcontent-margin-md * 2));
$section-padding: 60px;
$section-margin: 60px;
$section-bg: #f9f9f9;
// Top Bar
$topbar-height: 45px;
$topbar-line-height: (
  $topbar-height - 1
);
//-------------------- Header --------------------//
$header-height: 100px;
// Sticky Header
$sticky-header-height: 60px;
// Floating Header Offset
$header-floating-offset: 60px;
// Side Header
$side-header-width: 260px;
$side-header-bg: #ffffff;
//-------------------- Primary Menu --------------------//
$primary-menu-padding: (
  $header-height - 22) * 0.5;
$sticky-menu-padding: (
  $sticky-header-height - 22) * 0.5;
$primary-menu-font: $heading-font;
$primary-menu-font-weight: bold;
$primary-menu-font-size: 13px;
$primary-menu-font-tt: uppercase;
$primary-menu-font-color: #444444;
$primary-menu-font-spacing: 1px;
// Primary Menu Sub Menu
$primary-menu-submenu-width: 220px;
$primary-menu-submenu-font: $body-font;
$primary-menu-submenu-font-size: 12px;
$primary-menu-submenu-font-weight: 600;
// Primary Mega menu
$mega-menu-title: $heading-font;
//-------------------- Page Title-------------------- //
$page-title-padding: 50px;
$page-title-parallax-padding: 100px;
$page-title-mini-padding: 20px;
$page-title-bg: #f5f5f5;
$page-title-bg-dark: #333333;
$page-title-size: 28px;
$page-title-subtitle-size: 18px;
$page-title-parallax-size: 40px;
$page-title-parallax-subtitle-size: 22px;
$page-title-mini-size: 18px;
//-------------------- Portfolio --------------------//
//Columns Margin
$portfolio-2-margin: 20px;
$portfolio-3-margin: 15px;
$portfolio-4-margin: 12px;
$portfolio-5-margin: 10px;
$portfolio-6-margin: 6px;
//Columns Margin for Sidebar
$portfolio-2-margin-sb: 20px;
$portfolio-3-margin-sb: 15px;
$portfolio-4-margin-sb: 12px;
$portfolio-5-margin-sb: 0;
//Columns Margin for Both Sidebar
$portfolio-2-margin-bs: 20px;
$portfolio-3-margin-bs: 11px;
$portfolio-4-margin-bs: 0;
//-------------------- Shop --------------------//
//Columns Margin
$shop-3-margin: 30px;
$shop-4-margin: 20px;
//Columns Margin for Sidebar
$shop-2-margin-sb: 40px;
$shop-3-margin-sb: 25px;
//Columns Margin for Both Sidebar
$shop-2-margin-bs: 40px;
//-------------------- Blog --------------------//
//Columns Margin
$blog-2-margin: 40px;
$blog-3-margin: 30px;
$blog-4-margin: 28px;
//Columns Margin for Sidebar
$blog-2-margin-sb: 40px;
$blog-3-margin-sb: 25px;
//Columns Margin for Both Sidebar
$blog-2-margin-bs: 40px;
//-------------------- Social Icons Colors --------------------//
$facebook: #3b5998;
$delicious: #205cc0;
$paypal: #00588b;
$flattr: #f67c1a;
$android: #a4c639;
$smashmag: #e53b2c;
$gplus: #dd4b39;
$wikipedia: #333333;
$stumbleupon: #f74425;
$foursquare: #25a0ca;
$call: #444444;
$ninetyninedesigns: #f26739;
$forrst: #5b9a68;
$digg: #191919;
$spotify: #81b71a;
$reddit: #c6c6c6;
$blogger: #fc4f08;
$cc: #688527;
$dribbble: #ea4c89;
$evernote: #5ba525;
$flickr: #ff0084;
$google: #dd4b39;
$instapaper: #333333;
$klout: #ff5f52;
$linkedin: #0e76a8;
$vk: #2b587a;
$rss: #ee802f;
$skype: #00aff0;
$twitter: #00acee;
$youtube: #c4302b;
$vimeo: #86c9ef;
$aim: #fcd20b;
$yahoo: #720e9e;
$email3: #6567a5;
$macstore: #333333;
$myspace: #666666;
$podcast: #e4b21b;
$cloudapp: #525557;
$dropbox: #3d9ae8;
$ebay: #89c507;
$github: #171515;
$googleplay: #dd4b39;
$itunes: #222222;
$plurk: #cf5a00;
$pinboard: #0000e6;
$soundcloud: #ff7700;
$tumblr: #34526f;
$wordpress: #1e8cbe;
$yelp: #c41200;
$intensedebate: #009ee4;
$eventbrite: #f16924;
$scribd: #666666;
$stripe: #008cdd;
$print: #111111;
$dwolla: #ff5c03;
$statusnet: #131a30;
$acrobat: #d3222a;
$drupal: #27537a;
$buffer: #333333;
$pocket: #ee4056;
$bitbucket: #0e4984;
$stackoverflow: #ef8236;
$hackernews: #ff6600;
$xing: #126567;
$instagram: #3f729b;
$quora: #a82400;
$openid: #e16309;
$steam: #111111;
$amazon: #e47911;
$disqus: #e4e7ee;
$plancast: #222222;
$appstore: #000000;
$pinterest: #c8232c;
$fivehundredpx: #111111;
$si_text_color: #ffffff;


//Typogrpahy
$main-font-color: #333333;
$main-font-family: 'Arimo';
$main-font-size: 14px;
$main-font-weight: 400;

//Header
$header-bg: rgba(#ffffff, 0.97
);
$header-height: 113px;

//Footer
$footer-height: 134px;

//Preloader
$preloader-img: '../img/svg/loader.svg';

//Colors
$general-color: #ffca00;
$primary: #669ed7;
$primary-hover: lighten($primary, 10%);
$success: #0ba05c;
$success-hover: lighten($success, 10%);
$info: #669ed7;
$info-hover: lighten($info, 10%);
$warning: #ffca00;
$warning-hover: lighten($warning, 10%);
$danger: #c10841;
$danger-hover: lighten($danger, 10%);
$color-types: (
  warning $warning $warning-hover,
  danger $danger $danger-hover,
  info $info $info-hover,
  success $success $success-hover
);

//Media Breakpoints
$media-md: 1199px;
$media-sm: 991px;
$media-xs: 767px;

//Font Awesome
%font-awesome {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  transform: translate(0, 0);

  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
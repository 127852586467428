:root {
  //Accordions - Classic
  --accordion-bg-color: #fff;
  --accordion-title-color: #1a1a1a;
  --accordion-title-color-hover: #1a1a1a;
  --accordion-title-color-active: #1a1a1a;
  --accordion-content-text-color: #1a1a1a;
  --accordion-toggle-color: #1a1a1a;
  --accordion-toggle-color-hover: #F15352;
  ;
  --accordion-toggle-color-active: #F15352;
  --accordion-toggle-border-color: #1a1a1a;
  --accordion-toggle-border-color-hover: #F15352;
  --accordion-toggle-border-color-active: #F15352;
  --accordion-border-color: #e3e3e3;
  --accordion-border-color-hover: #F15352;
  --accordion-border-color-active: #F15352;

  //Accordions - Alternative
  --accordion-alt-bg-color: #fff;
  --accordion-alt-title-color: #1a1a1a;
  --accordion-alt-title-color-hover: #1a1a1a;
  --accordion-alt-content-text-color: #1a1a1a;
  --accordion-alt-toggle-color: #1a1a1a;
  --accordion-alt-toggle-color-hover: #F15352;
  --accordion-alt-toggle-border-color: #1a1a1a;
  --accordion-alt-toggle-border-color-hover: #1a1a1a;
  --accordion-alt-border-color: #ccc;
  --accordion-alt-border-color-hover: #1a1a1a;

  .dark-mode {
    //Accordions - Classic
    --accordion-bg-color: #fff;
    --accordion-title-color: #1a1a1a;
    --accordion-title-color-hover: #1a1a1a;
    --accordion-content-text-color: #1a1a1a;
    --accordion-toggle-color: #1a1a1a;
    --accordion-toggle-color-hover: #1a1a1a;
    --accordion-border-color: #1a1a1a;
    --accordion-border-color-hover: #1a1a1a;

    //Accordions - Alternative
    --accordion-alt-bg-color: #fff;
    --accordion-alt-title-color: #1a1a1a;
    --accordion-alt-title-color-hover: #1a1a1a;
    --accordion-alt-content-text-color: #1a1a1a;
    --accordion-alt-toggle-color: #1a1a1a;
    --accordion-alt-toggle-color-hover: #1a1a1a;
    --accordion-alt-border-color: #1a1a1a;
    --accordion-alt-border-color-hover: #1a1a1a;
  }
}

.accordions.active {
  margin-bottom: 20px;

  .accordion:first-of-type {
    button {
      margin-top: 0;
    }
  }

  // zero out h2 if accordions are active (JS is active)
  h2.accordion-title {
    position: relative;
    padding: 0;
    margin: 0;

    &:after {
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      width: 70px;
      height: 2px;
      background: var(--accordion-toggle-border-color);
      content: '';
    }

    &:hover,
    &:focus {
      &:after {
        background: var(--accordion-toggle-border-color-hover);
      }
    }

    &.is-active {
      button {
        color: var(--accordion-title-color-active);

        &::before {
          background: var(--accordion-border-color-active);
        }

        &::after {
          transform: rotate(180deg);
        }
      }

      &::after {
        background: var(--accordion-toggle-border-color-active);
      }

      .accordion-content {
        padding: 0;
        margin-top: 0;
        margin-bottom: 10px;
        font-weight: 400;
        color: var(--accordion-content-text-color);
        @include fontsize(16 24);
      }

      p:last-child,
      ul:last-child,
      ol:last-child {
        margin-bottom: 0;
      }
    }
  }

  // style just the button so that h2's maintain default styling
  button {
    position: relative;
    width: 100%;
    padding: 20px 85px 15px 0;
    margin-bottom: 0;
    color: var(--accordion-title-color);
    text-align: left;
    cursor: pointer;
    background: transparent;
    border: 0;
    // @include primary-font-bold;
    @include fontsize(18 24);

    @include medium-up {
      margin-top: 15px;
    }

    &:hover,
    &:focus {
      color: var(--accordion-title-color-hover);
      background: transparent;
      border: 0;
      outline: 0;

      &:before {
        background: var(--accordion-border-color-hover);
        opacity: 1;
      }

      &:after {
        color: var(--accordion-toggle-color-hover);
      }
    }

    &:before {
      position: absolute;
      bottom: 0;
      display: block;

      // width: 100%;
      width: calc(100% - 75px);
      height: 2px;
      background: #818181;
      opacity: .3;
      border-radius: 50px;
      content: '';
    }

    &:after {
      // width: 16px;
      // height: 10px;
      // background-image: url(/themes/canvas-template/assets/dist/images/ICN-arrow.svg);
      // background-repeat: no-repeat;
      // background-size: cover;
      opacity: 1;
      position: absolute;
      top: 27px;
      right: 28px;
      background-color: transparent;
      font-family: FontAwesome;
      font-weight: 900;
      @include fontsize(14 14);
      content: '\f078';
    }
  }

  .accordion-title+[aria-hidden] {
    display: none;
  }

  .accordion-title+[aria-hidden='false'] {
    display: block;
  }

  .accordion-content {
    padding: 15px 0;

    p {
      @include fontsize(18 28);
    }
  }
}

.accordions-alt-list {
  position: relative;

  .accordion-alt-item {
    position: relative;
    display: block;
    padding: 40px 0 30px;
    margin-bottom: 10px;

    +.accordion-alt-item {
      border-top: 1px solid var(--accordion-alt-border-color);
    }

    .content,
    &__content {
      width: 100%;
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid var(--accordion-alt-border-color);

      &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      @include large-up {
        display: inline-flex;
      }

      .description {
        h2 {
          margin-top: 0;
          margin-bottom: 5px;
          // @include primary-font-bold;
          color: var(--accordion-alt-title-color);
          text-transform: none;
          @include fontsize(20 24);
        }

        .blurb {
          p {
            margin-bottom: 20px !important;
            @include fontsize(18 28);
          }
        }

        .read-more {
          position: relative;
          display: inline-block;
          margin: 0;
          font-size: 16px;
          font-weight: 900;
          color: var(--accordion-alt-toggle-color);
          cursor: pointer;
          background: transparent;
          border: 0;
          appearance: none;
          align-items: center;

          &:hover,
          &:focus {
            color: var(--accordion-alt-toggle-color-hover);
            border-bottom: 0;
            text-decoration: none;
          }

          &::before {
            content: none;
          }

          &::after {
            opacity: 1;
            position: absolute;
            top: 7px;
            right: -18px;
            background-color: transparent;
            font-family: FontAwesome;
            font-weight: 900;
            @include fontsize(12 11);
            content: '\f078';
          }
        }
      }


      &.expanded {
        .more-alt-content {
          display: block;
        }

        .read-more {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__image {
      width: 100%;
      margin-bottom: 30px;
      text-align: center;

      @include medium-up {
        z-index: 1;
        max-width: 200px;
        min-width: 200px;
        margin-right: 40px;
        overflow: hidden;
      }

      img,
      figure {
        width: 100%;
        border-radius: 10px;

        @include medium-up {
          margin: 0;
          object-fit: cover;
          flex: 1;
        }
      }
    }

    .more-alt-content {
      display: none;
      padding-top: 0;
      padding-bottom: 20px;

      p:last-of-type {
        margin-bottom: 0;
      }

      a.btn {
        width: 230px;
        padding: 5px 35px 5px 15px;
        margin-right: 15px;
        font-size: 16px;

        &:before {
          left: -30px;
        }

        &:hover,
        &:focus {
          &:before {
            width: calc(100% + 30px);
          }
        }
      }
    }
  }
}


//RTE Styling
.accordion[data-arx-type='accordion'],
.accordion[data-arx-type='layer'] {
  position: relative;
  margin-bottom: 15px;
  background: repeating-linear-gradient(to right, rgba(0, 88, 251, 0.04), rgba(0, 88, 251, 0.04) calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc(100% / var(--arx-grid-columns)));
  outline: 2PX dashed rgba(0, 88, 251, 0.2);
}

.accordion-preview[data-arx-type='previewaccordion'],
.accordion-alt-item[data-arx-type='imageaccordion'],
.more-alt-content[data-arx-type='accordion'],
.accordion-preview[data-arx-type='layer'],
.accordion-alt-item[data-arx-type='layer'],
.more-alt-content[data-arx-type='layer'] {
  position: relative;
  padding: 30px 10px;
  margin-bottom: 15px;
  background: repeating-linear-gradient(to right, rgba(0, 88, 251, 0.04), rgba(0, 88, 251, 0.04) calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc(100% / var(--arx-grid-columns)));
  outline: 2PX dashed rgba(0, 88, 251, 0.2);

  .image figure[data-arx-type="image"] {
    position: relative;
    padding-top: 10px;
    margin-bottom: 15px;
    background: repeating-linear-gradient(to right, rgba(0, 88, 251, 0.04), rgba(0, 88, 251, 0.04) calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)), transparent calc(100% / var(--arx-grid-columns)));
    outline: 2px dashed rgba(0, 88, 251, 0.2);

    @include large-up {
      padding: 40px;
      box-shadow: 0 0 25px rgba(black, 0.25);
    }

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      padding: 2px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      background: rgba(0, 88, 251, 0.04);
      border: 1px dashed rgba(0, 88, 251, 0.2);
      border-top: 0;
      border-right: 0;
      content: 'Accordion Image';
    }
  }
}

.more-alt-content[data-arx-type='accordion'],
.more-alt-content[data-arx-type='layer'] {
  padding: 30px 10px;
}
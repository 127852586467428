:root {
  //Image List
  --image-list-bg: transparent;
  --image-list-border-color: #cccccc;
  --figcaption-bg: #1a1a1a;

  .dark-mode {
    --image-list-bg: transparent;
    --image-list-border-color: #cccccc;
  }
}

//Use figure as root for img
img {
  display: block;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;

  @include medium-up {
    max-width: 100%;
  }

  &.retina {
    display: none;
    max-width: 50%;
  }

  &[class*='outset'] {
    margin: 0 auto;
    float: none;

    @include medium-up {
      margin: 0 auto;
      float: none;
    }
  }

  &[class*='left'] {
    margin: 0 0 15px 0;

    @include medium-up {
      margin: 0 50px 20px 0;
      float: left;
      max-width: 40%;
    }
  }

  &[class*='right'] {
    margin: 0 0 15px 0;

    @include medium-up {
      float: right;
      max-width: 40%;
      margin: 0 0 20px 50px;
    }
  }

  &[class*='center'],
  &[class*='both'] {
    margin: 0 auto 15px auto;

    @include medium-up {
      margin: 0 auto 20px auto;
    }
  }
}

figure {
  display: table;
  margin: 0;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;

  @include medium-up {
    max-width: 100%;
  }

  //.image-link
  a {
    position: relative;
    display: block;
    overflow: hidden;

    img {
      transition: all .25s ease-in-out;
    }

    &:hover,
    &:focus {
      img {
        transform: scale(1.025);
      }
    }
  }

  &.max-width {
    width: 100%;

    img {
      width: 100%;
    }
  }

  &.rounded-corners {
    img {
      border-radius: 10px;
      overflow: hidden;
    }
  }

  &.offsetLeft {
    margin-left: -50px;
  }

  &.offsetRight {
    margin-right: -50px;
  }

  &.rounded-corners {
    img {
      border-radius: 10px;
      overflow: hidden;
    }
  }

  &.retina {
    display: none;
    max-width: 50%;
  }

  @at-root .homepage .tab-current figure.zoomin{
    animation: 3s ease-in-out fadein, 1.5s ease-in-out zoomin;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  img[class*='outset'] {
    margin: 0 auto;
    float: none;

    @include medium-up {
      margin: 0 auto;
      float: none;
    }
  }

  &[class*='left'] {
    margin: 0 0 15px 0;

    @include medium-up {
      float: left;
      max-width: 40%;
      margin: 0 50px 20px 0;
    }
  }

  &[class*='right'] {
    margin: 0 0 15px;

    @include medium-up {
      float: right;
      max-width: 40%;
      margin: 0 0 20px 50px;
    }
  }

  &[class*='center'],
  &[class*='both'] {
    margin: 0 auto 15px auto;

    @include medium-up {
      margin: 0 auto 20px auto;
    }
  }

  figcaption {
    display: table-caption;
    background: var(--figcaption-bg);
    @include fontsize(15 18);
    font-style: italic;
    color: white;
    text-align: center;
    padding: 10px 25px;
    margin-top: 1px;
    caption-side: bottom;
  }
}

// image list
.image-list {
  display: flex;
  flex-wrap: wrap;
  margin: 35px 0;

  @include medium-up {
    flex-wrap: nowrap;
    flex-grow: 0;
    justify-content: space-between;
  }

  + .image-list {
    border-top: 1px solid var(--image-list-border-color);
    padding: 35px 0;
    margin: 0;
  }

  h2,
  h3,
  h4 {
    margin-top: 0;
  }

  .image,
  &__image {
    width: 100%;
    text-align: center;
    margin: 0 0 15px;
    border-radius: 10px;

    @include medium-up {
      display: flex;
      align-content: stretch;
      align-items: stretch;
      width: 220px;
      overflow: hidden;
      margin: 0 40px 0 0;
      max-height: 150px;

      img,
      figure {
        @include medium-up {
          margin: 0;
          object-fit: cover;
          flex: 1;
          min-height: 150px;
        }
      }
    }
  }

  .content,
  &__content {
    width: 100%;
    
    @include medium-up {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 230px);
      align-items: center;
      align-content: center;
    }

    > * {
      width: 100%;
    }

    h1,
    h2,
    h3 {
      @include fontsize(24 30);
      margin: 0 0 5px;
    }

    p {
      @include fontsize(18 28);

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.zoom-img,
.zoom-gal {
  cursor: zoom-in;

  img {
    transition: all .2s ease-in-out;

    &:hover,
    &:focus {
      transform: scale(1.02);
    }
  }
}

.bg-image:not([data-arx-type="image"]) {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}